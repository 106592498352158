import React, { FC, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { ProctoringCamera } from '../../../../assets/icons/ProctoringCamera';
import { useAppSelector } from '../../../../store/store';
import { IDocImage } from '../../../../types/entities/entitites';
import { IProctoringCard } from '../../../../types/interfaces/interfaces';
import { proctoImgConfigurator } from '../../../../utils/const';
import { proctoringUrl } from '../../../../utils/ProfileRegistration';
import { ProctoringView } from '../../../Modal/ProctoringView/ProctoringView';
import styles from './ProctoringCard.module.css';


export const ProctoringCard: FC<IProctoringCard> = ({ proctoring, openProctoringModal, deleteDocs }) => {
  const { _isLoadButton, s3_token } = useAppSelector(({ profileSlice }) => profileSlice);

  const [proctoringModal, setProctoringModal] = useState(false);
  const [proctoringImage, setProctoringImage] = useState('');
  const [proctoringImageItem, setProctoringImageItem] = useState<IDocImage | null>(null);

  const openViewModal = (image: string, item:IDocImage) => {
    setProctoringImage(image);
    setProctoringModal(true);
    setProctoringImageItem(item);
  };

  return (
    <>
      <div className={styles.cardBlock}>
        {
          proctoring.map((item, index) => (
            item.type === 'proctoring'
              ? (
                <div
                  className={styles.proctoringCard}
                  key={String(item.image)}
                >
                  <div
                    className={styles.proctoringBlock}
                    onClick={() => openViewModal(`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`, item)}
                  >
                    <div
                      className={styles.deleteProctoringIcon}
                      onClick={(e) => deleteDocs({ e, typeDoc: 'proctoring', fileName: String(item.image) })}
                    >
                      <DeleteOutlined />
                    </div>
                    <img
                      className={styles.proctoringImage}
                      src={`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`}
                      alt=""
                      onError={({ currentTarget }) => proctoringUrl(currentTarget, item, s3_token)}
                    />
                  </div>
                  <span className={styles.proctoringPhotoCounter}>{`${index + 1} фото`}</span>
                </div>
              )
              : (
                <div key={index} className={styles.proctoringCard}>
                  <button
                    className={styles.photoBlock}
                    onClick={openProctoringModal}
                    disabled={_isLoadButton}
                  >
                    <span className={styles.photoSvg}>
                      <ProctoringCamera />
                      <span className={styles.text}>{`Загрузите фото ${index + 1}`}</span>
                    </span>
                  </button>
                </div>
              )
          ))
        }
      </div>
      <ProctoringView
        img={proctoringImage}
        modal={proctoringModal}
        setModal={setProctoringModal}
        proctoringImageItem={proctoringImageItem}
        s3_token={s3_token}
      />
    </>
  );
};
