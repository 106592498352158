import React from 'react';
import { Tabs, TabsProps } from 'antd';
import { NoteIcon } from '../../assets/icons/NoteIcon';
import { UserIcon } from '../../assets/icons/UserIcon';
import { Documentation } from '../../components/Profile/Documentation';
import { ProfileInd } from '../../components/Profile/Profile';
import styles from './ProfileIndex.module.css';


export const Profile = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <span className={styles.tab}><UserIcon /><span className={styles.tabs_text}>Общие данные</span></span>,
      children: <ProfileInd />,
    },
    {
      key: '2',
      label: <span className={styles.tab}><NoteIcon /><span className={styles.tabs_text}>Документы</span></span>,
      children: <Documentation />,
    },
  ];

  return (
    <div className={styles.body}>
      <Tabs
        className={styles.tabs}
        tabPosition="left"
        defaultActiveKey={'1'}
        items={items}
      />
    </div>
  );
};
