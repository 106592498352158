import { AcronymEnum } from '../types/entities/entitites';

export const postPhoneFunc = (phone:string) => {
  const eight = '8';
  return eight + phone.replace(/[^+\d]/g, '').slice(2);
};

export const getPhoneFunc = (phone:string) => {
  const seven = '+7';
  return seven + phone.slice(1);
};

export const phoneMask = (phone:string) => {
  const mask = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
  return phone?.replace(mask, '+7 ($2) $3-$4-$5');
};


export const regexObj: {
  [key in AcronymEnum]: {
    regex: RegExp,
    regexTransform: string
  }
} = {
  RF: {
    regex: /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
    regexTransform: '+7 $2 $3 $4 $5',
  },
  KZ: {
    regex: /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
    regexTransform: '+7 $2 $3 $4 $5',
  },
  BY: {
    regex: /(375)[\s(]?(\d{2})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
    regexTransform: '+375 $2 $3 $4 $5',
  },
  KG: {
    regex: /(996)[\s(]?(\d{3})[\s)]?(\d{2})[\s-]?(\d{2})[\s-]?(\d{2})/g,
    regexTransform: '+996 $2 $3 $4 $5',
  },
  UZ: {
    regex: /(998)[\s(]?(\d{2})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
    regexTransform: '+998 $2 $3 $4 $5',
  },
  AM: {
    regex: /(374)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
    regexTransform: '+374 $2 $3 $4 $5',
  },
  MN: {
    regex: /(976)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{4})/g,
    regexTransform: '+976 $2 $3 $4',
  },
  CN: {
    regex: /(86)[\s(]?(\d{3})[\s)]?(\d{4})[\s-]?(\d{4})/g,
    regexTransform: '+86 $2 $3 $4',
  },
};
