import React, { FC } from 'react';
import { Button, Col, message, Modal, Row } from 'antd';
import cn from 'classnames';
import { ErrorIcon } from '../../../assets/icons/ErrorIcon';
import { IProctoringModal } from '../../../types/interfaces/interfaces';
import styles from './ProctoringModal.module.css';

export const ProctoringModal: FC<IProctoringModal> = ({
  proctoringImg,
  proctoringModal,
  closeProctoringModal,
  clearPhoto,
  uploadProctoringImage,
  photoRef,
  videoRef,
  setProctoringImg,
}) => {
  const takePhoto = () => {
    if (!videoRef.current?.srcObject?.active || !photoRef.current) {
      message.error({
        className: 'messageError',
        content: 'У вас нет активной камеры',
        icon: <ErrorIcon />,
      });
      return;
    }
    const photoResolution = 640 / (4 / 3);
    const video = videoRef.current;
    const photo = photoRef.current;
    photo.width = 640;
    photo.height = photoResolution;
    const ctx = photo.getContext('2d');
    ctx.drawImage(video, 0, 0, 640, photoResolution);
    setProctoringImg(photo.toDataURL('image/jpeg'));
    // clearInterval(faceInterval)
  };

  return (
    <Modal
      title={[
        <Row>
          <Col span={24}>
            <h2 className={styles.title}>
              Фото для прокторинга
            </h2>
          </Col>
        </Row>,
      ]}
      open={proctoringModal}
      onCancel={closeProctoringModal}
      width={1100}
      centered={true}
      footer={[
        <div className={styles.proctoringFooter}>
          <Button
            type="default"
            onClick={proctoringImg ? clearPhoto : closeProctoringModal}
          >
            { proctoringImg ? 'Сделать другой снимок' : 'Отменить' }
          </Button>
          <Button
            type="primary"
            onClick={proctoringImg ? uploadProctoringImage : takePhoto}
          >
            { proctoringImg ? 'Использовать фото' : 'Сделать снимок' }
          </Button>
        </div>,
      ]}
    >
      <Row justify={'center'} align={'middle'}>
        <div className={styles._proctoringContent}>
          <div className={cn(styles.camera, { [styles.cameraActive]: proctoringImg })}>
            <video ref={videoRef} />
          </div>
          <div className={styles.photo}>
            <canvas
              className={cn(styles.proctoringPhoto, { [styles.proctoringPhotoActive]: proctoringImg })}
              ref={photoRef}
            />
          </div>
        </div>
      </Row>
    </Modal>
  );
};
