import React, { FC } from 'react';
import { Input } from 'antd';
import { IInputPasswordProfile } from '../../types/interfaces/interfaces';

const Index: FC<IInputPasswordProfile> = ({ value, onChange, type, placeholder }) => <Input.Password
  style={{ width: '100%' }}
  value={value}
  onChange={onChange}
  type={type}
  placeholder={placeholder}
  allowClear
/>;

export default Index;
