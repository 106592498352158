import React, { FC, useState } from 'react';
import { PinInput } from 'react-input-pin-code';
import { Button, Modal } from 'antd';
import { confirmTelegramCode } from '../../../store/actions/telegramActions';
import { ConfirmTelegramModalVisible } from '../../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { brightRed, greenRgbColor, pastelBlue, PinContainerStyle, PinInputStyle } from '../../../utils/const';
import styles from './ConfirmTelegramModal.module.css';


export const ConfirmTelegramModal:FC = () => {
  const [values, setValues] = useState(new Array(4).fill(''));
  const { _token, _is_confirm_telegram_modal_visible } = useAppSelector(({ profileSlice }) => profileSlice);
  const dispatch = useAppDispatch();

  const confirmCode = () => {
    const data = {
      code: values.join(''),
      token: _token,
    };
    dispatch(confirmTelegramCode(data));
  };

  const onHandlerCancel = () => {
    dispatch(ConfirmTelegramModalVisible(false));
  };

  return (
    <Modal
      className={styles.modal}
      open={_is_confirm_telegram_modal_visible}
      onCancel={onHandlerCancel}
      footer={[
        <Button
          type="primary"
          htmlType="submit"
          className={styles.modalBtn}
          disabled={values.join('').length < 4}
          onClick={confirmCode}
        >
          Отправить
        </Button>,
      ]}
    >
      <h2 className={styles.title}>
        Подтвердить код Telegram
      </h2>
      <p className={styles.text}>
        Мы отправили код подтверждения на ваш Telegram
      </p>

      <PinInput
        containerStyle={PinContainerStyle}
        inputStyle={PinInputStyle}
        autoFocus={true}
        placeholder={''}
        values={values}
        onChange={(value, index, values) => setValues(values)}
        validBorderColor={greenRgbColor}
        focusBorderColor={pastelBlue}
        errorBorderColor={brightRed}
      />
    </Modal>
  );
};
