import React, { FC, useEffect, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import cn from 'classnames';
import { Drots } from '../../../assets/icons/Drots';
import { EmailIcon } from '../../../assets/icons/EmailIcon';
import { PhoneIcon } from '../../../assets/icons/PhoneIcon';
import { sendSmsCode } from '../../../store/actions/profileAction';
import { changeAcronym, ModalVisibleForChangeEmail, ModifiedPhone } from '../../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { InputStyle } from '../../../style/Input/InputStyle';
import { AcronymEnum } from '../../../types/entities/entitites';
import { IContactForm } from '../../../types/interfaces/interfaces';
import { emailValid } from '../../../utils';
import { getMask, selectOptions } from '../../../utils/const';
import { phoneMask } from '../../../utils/PhoneFilter';
import { getEmailValue, onConfirmEmail } from '../../../utils/ProfileRegistration';
import styles from './ContactForm.module.css';


export const ContactForm: FC<IContactForm> = ({
  _token,
  setEditPhoneModal,
  form,
  isRegistrationPage = false,
}) => {
  const { _isLoadButton, emailConfirm, phoneConfirm, values } = useAppSelector(({ profileSlice }) => profileSlice);
  const [confirmEmailDisabled, setConfirmEmailDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+7');
  const [mask, setMask] = useState('(000)000-00-00');
  const [acronym, setAcronym] = useState<AcronymEnum>(AcronymEnum.rf);
  const userPhone = phoneMask(values.phone);
  const userEmail = values.email;

  useEffect(() => {
    if (emailConfirm) {
      form.validateFields(['email']);
    }
  }, [emailConfirm]);

  const onHandleClickForEmail = () => {
    if (emailConfirm) {
      dispatch(ModalVisibleForChangeEmail(true));
      return;
    }
    onConfirmEmail({ form, _token, dispatch });
  };

  const onHandleClickForPhone = () => {
    const russianPhone = values.phone.startsWith('89') && values.phone.length === 11;
    let phoneValue = '';
    if (userPhone && !phoneConfirm) {
      phoneValue = values.phone;
    }
    else {
      phoneValue = countryCode + phone.replace(/[^+\d]/g, '');
    }
    dispatch(ModifiedPhone(phoneValue));
    dispatch(changeAcronym(acronym));

    if (russianPhone && !phoneConfirm) {
      dispatch(sendSmsCode({ _token, phoneValue }));
      return;
    }

    if (russianPhone && phoneConfirm) {
      setEditPhoneModal(true);
      return;
    }

    if (!russianPhone && isRegistrationPage && !phoneConfirm) {
      dispatch(sendSmsCode({ _token, phoneValue }));
      return;
    }

    if (!russianPhone && phoneConfirm) {
      setEditPhoneModal(true);
      return;
    }

    if (!russianPhone && !isRegistrationPage) {
      setEditPhoneModal(true);
    }
  };

  const buttonText = useMemo(() => {
    const russianPhone = values.phone.startsWith('89') && values.phone.length === 11;
    if (values.phone && !russianPhone) {
      return 'Изменить';
    }
    return phoneConfirm ? 'Изменить' : 'Подтвердить';
  }, [phoneConfirm]);

  useEffect(() => {
    setPhone('');
  }, [acronym]);

  useEffect(() => {
    if (countryCode === '7' || countryCode === '+7') {
      setCountryCode('8');
    }
  }, [countryCode]);

  const handleChange = (value: string) => {
    const countryValue = JSON.parse(value);
    const currentCountryCode = countryValue.code.replace(/[^0-9]/g, '');
    setAcronym(countryValue.acronym);
    setCountryCode(currentCountryCode);
    setPhone('0');
    setMask(getMask(currentCountryCode));
  };

  const onSetPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneValue = countryCode + e.target.value.replace(/[^+\d]/g, '');
    form.setFieldsValue({ phone: phoneValue });
    setPhone(e.target.value);
  };

  const phoneConfirmText = useMemo(() => {
    const russianPhone = values.phone.startsWith('89') && values.phone.length === 11;
    if (!russianPhone) {
      return 'Подтвержден';
    }
    return phoneConfirm ? 'Подтвержден' : 'Не подтвержден';
  }, [values.phone, phoneConfirm]);

  return (
    <Row gutter={32} align="middle" className={styles.contactForm}>
      <Col xs={24} md={24} lg={24} xl={12}>
        <Row
          gutter={20}
          align="middle"
          className={cn(styles.contactFormPhone, { [styles.contactFormInfo]: phoneConfirm || userPhone })}
        >
          <Col className={styles.contactFormInput}>
            <Form.Item
              label={phoneConfirm || userPhone ? '' : 'Телефон'}
              name={'phone'}
              rules={[
                { required: true, message: 'Введи номер телефона!' },
              ]}
              hasFeedback
            >
              {phoneConfirm || userPhone
                ? (
                  <div className={styles.contactFormInfoBlock}>
                    <Icon
                      component={PhoneIcon}
                      className={styles.contactFormIcon}
                    />
                    <div className={styles.userInfoBlockMain}>
                      <p className={styles.userInfoValue}>{userPhone}</p>
                      <p className={styles.userInfoLabel}>{phoneConfirmText}</p>
                    </div>
                  </div>
                )
                : (
                  <Input.Group className={styles.inpGroup}>
                    <Select
                      defaultValue='{"code": "+7", "acronym": "RF"}'
                      onChange={handleChange}
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      disabled={!!phoneConfirm}
                    >
                      {selectOptions}
                    </Select>
                    <span className={styles.line} />
                    <MaskedInput
                      className={styles.maskedInput}
                      mask={mask}
                      name="phone"
                      size="middle"
                      allowClear
                      type="text"
                      placeholder={mask}
                      value={phone}
                      disabled={!!phoneConfirm}
                      onChange={onSetPhone}
                    />
                    <Icon component={Drots} className={styles.drots} />
                  </Input.Group>
                )
              }
            </Form.Item>
          </Col>
          <Col className={styles.contactFormBtn}>
            <Button
              type="link"
              onClick={onHandleClickForPhone}
              loading={_isLoadButton}
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Col>

      <Col xs={24} md={24} lg={24} xl={12}>
        <Row
          gutter={20}
          align="middle"
          className={cn({ [styles.contactFormInfo]: emailConfirm })}
        >
          <Col flex="auto">
            <Form.Item
              rules={[
                { type: 'email', required: false, message: 'test@mail.ru', pattern: emailValid },
              ]}
              hasFeedback
              label={emailConfirm ? '' : 'E-mail'}
              name="email"
            >
              {emailConfirm
                ? (
                  <div className={styles.contactFormInfoBlock}>
                    <Icon
                      component={EmailIcon}
                      className={styles.contactFormIcon}
                    />
                    <div className={styles.userInfoBlockMain}>
                      <p className={styles.userInfoValue}>{userEmail}</p>
                      <p className={styles.userInfoLabel}>Подтвержден</p>
                    </div>
                  </div>
                )
                : (
                  <InputStyle
                    name="email"
                    disabled={!!emailConfirm}
                    type="text"
                    placeholder="Введите e-mail"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => getEmailValue({ e, setConfirmEmailDisabled })}
                  />
                )
              }

            </Form.Item>
          </Col>
          <Col>
            <Button
              type="link"
              loading={_isLoadButton}
              disabled={confirmEmailDisabled}
              onClick={onHandleClickForEmail}
            >
              {emailConfirm ? 'Изменить' : 'Подтвердить'}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
