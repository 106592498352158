export const PublicServicesIcon = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.1819 9.28803C27.0718 7.84507 25.9704 5.99512 24.869 5.10715C23.6304 4.1984 22.3435 3.35851 21.0141 2.59122C19.6947 1.8031 18.3343 1.08676 16.9389 0.445284C16.1935 0.138846 15.3942 -0.0121893 14.5893 0.00129732C13.784 -0.0157526 12.984 0.135417 12.2396 0.445284C10.8444 1.08722 9.48408 1.80354 8.16443 2.59122C6.03506 3.88618 4.30954 5.10715 4.30954 5.10715C3.45518 5.82773 2.79619 6.74339 2.38084 7.76825C2.25533 8.07794 2.49522 8.39991 2.82938 8.39991H4.1265C4.49804 8.39991 4.83284 8.18959 5.03997 7.88114C5.33248 7.44554 5.68679 7.04988 6.09515 6.70783C6.09515 6.70783 7.51988 5.70664 9.27805 4.64478C10.3677 3.99888 11.4908 3.41149 12.6428 2.88511C13.2575 2.63101 13.918 2.50706 14.5829 2.52104C15.2475 2.50998 15.9075 2.63383 16.523 2.88511C17.6751 3.41112 18.7983 3.99852 19.8877 4.64478C20.9854 5.27395 22.048 5.96266 23.0706 6.70783C23.98 7.43597 24.8894 8.95293 24.9804 10.1362C24.9804 10.1362 25.1926 11.8958 25.2229 13.9801C25.221 15.2673 25.1502 16.5536 25.0107 17.8332C24.7992 19.1731 24.11 20.3909 23.0706 21.2615C23.0706 21.2615 21.6459 22.293 19.8877 23.3549C18.7981 24.0008 17.6749 24.5882 16.523 25.1146C15.9083 25.3687 15.2478 25.4926 14.5829 25.4786C13.9189 25.4926 13.259 25.3718 12.6428 25.1237C11.4907 24.5977 10.3675 24.0103 9.27805 23.364C7.51988 22.3325 6.09515 21.2706 6.09515 21.2706C5.73559 20.9827 5.37604 20.5715 5.0652 20.1064C4.86216 19.8026 4.53083 19.5998 4.16542 19.5998H2.84208C2.50365 19.5998 2.26477 19.9289 2.40055 20.2389C2.85285 21.2715 3.5812 22.2794 4.30954 22.8666C4.30954 22.8666 6.03506 24.1616 8.16443 25.4196C9.48383 26.2077 10.8442 26.924 12.2396 27.5655C12.9858 27.8681 13.7851 28.0154 14.5893 27.9984C15.3945 28.0154 16.1945 27.8643 16.9389 27.5544C18.3341 26.9125 19.6944 26.1961 21.0141 25.4085C23.1434 24.1135 24.869 22.8555 24.869 22.8555C26.1278 21.7938 26.9625 20.3086 27.2186 18.6747C27.3876 17.1141 27.4734 15.5456 27.4756 13.9758C27.4389 11.434 27.1819 9.28803 27.1819 9.28803Z" fill="url(#paint0_linear_1317_8616)" />
    <path d="M1.70508 10.9213C1.70508 10.612 1.9558 10.3613 2.26508 10.3613H10.9451C11.2544 10.3613 11.5051 10.612 11.5051 10.9213V12.6013C11.5051 12.9106 11.2544 13.1613 10.9451 13.1613H1.70508V10.9213Z" fill="#0066B3" />
    <path d="M1.70508 14.8408H19.3451C19.6544 14.8408 19.9051 15.0915 19.9051 15.4008V17.0808C19.9051 17.3901 19.6544 17.6408 19.3451 17.6408H2.26508C1.9558 17.6408 1.70508 17.3901 1.70508 17.0808V14.8408Z" fill="#EE2F53" />
    <defs>
      <linearGradient id="paint0_linear_1317_8616" x1="14.8169" y1="0" x2="14.8169" y2="27.9997" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0066B3" />
        <stop offset="0.354167" stopColor="#0066B3" />
        <stop offset="0.6875" stopColor="#EE2F53" />
        <stop offset="1" stopColor="#EE2F53" />
      </linearGradient>
    </defs>
  </svg>
);
