import React, { FC, useMemo } from 'react';
import { Progress, Row } from 'antd';
import cn from 'classnames';
import { useAppSelector } from '../../store/store';
import styles from './ProgressStyle.module.css';

const ProgressStyle: FC<{ isChangePasswordForm?: boolean }> = ({ isChangePasswordForm }) => {
  const { _progress, _progressCount } = useAppSelector(({ profileSlice }) => profileSlice);

  const colorStoke = useMemo(() => {
    if (_progressCount >= 0 && _progressCount < 50) {
      return { color: '#EB5757', name: 'red' };
    }
    if (_progressCount >= 75 && _progressCount <= 100) {
      return { color: '#73D071', name: 'green' };
    }
    return { color: '#F3B944', name: 'yellow' };
  }, [_progressCount]);

  return (
    <Row className={cn(styles.progress, { [styles.progressBig]: isChangePasswordForm })}>
      {_progress &&
        <Progress
          strokeColor={colorStoke.color}
          percent={Number(_progressCount)}
          size="default"
        />
      }

      <span
        className={`${styles.text} ${_progress === 'Нет пароля' ? '' : styles[colorStoke.name]}`}
      >
        Надежность: {_progress === 'Нет пароля' ? '' : _progress}
      </span>
    </Row>
  );
};

export default ProgressStyle;
