import React, { FC } from 'react';
import { Input } from 'antd';
import { IInputStyle } from '../../types/interfaces/interfaces';

export const InputStyle: FC<IInputStyle | any> = ({ name, value, onChange, type, placeholder, disabled, maxLength, className }) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (<Input
    name={name}
    maxLength={maxLength}
    value={value}
    onChange={onChange}
    type={type}
    placeholder={placeholder}
    allowClear
    disabled={disabled}
    className={className}
    onKeyPress={handleKeyPress}
  />);
};
