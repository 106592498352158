import { Dispatch } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import moment from 'moment';
import { requestSMSforEmail, sendSmsCode } from '../store/actions/profileAction';
import { ModalVisible, ModalVisibleForEmail } from '../store/profileSlice';
import { IDocImage } from '../types/entities/entitites';
import { IJSONData, IOnChangePhoneModalShow, IOnSearchAddressSelect } from '../types/interfaces/interfaces';
import { base_image_url, imgConfigurator, proctoImgConfigurator } from './const';
import { validateInn } from './Validates';
import { emailValid } from '.';


export const dataInJsonFormat = (
  { values, _token, fiasAddress, fiasId, startDate, images }: { values: IJSONData, _token:string, fiasAddress: string | null, fiasId: string | null, startDate:Date, images?: any },
) => {
  const year = moment(startDate, 'DD.MM.YYYY').format('YYYY');
  const month = moment(startDate, 'DD.MM.YYYY').format('MM');
  const day = moment(startDate, 'DD.MM.YYYY').format('DD');
  return JSON.stringify({
    token: _token,
    email: values.email?.toLowerCase(),
    login: values.email,
    sex: values.sex,
    user_info: {
      geo: { fias_addr: fiasAddress, fias_id: fiasId },
      work: { place: values.place, industry: values.industry, position: values.position, inn: values.inn, ogrn: values.ogrn },
      birthday: {
        year: Number(year),
        month: Number(month),
        day: Number(day),
      },
      docs: { snils: values.snils, snils_dop: values.snils_dop, id_doc: values.idDoc, id_doc_name: values.idDocName, images },
      passport: { name: values.name, sur_name: values.sur_name, patronymic: values.patronymic },
    },
  });
};

export const checkChange = async({ e, setCheckNick, form } : { e: CheckboxChangeEvent, setCheckNick: (b:boolean) => void, form: any }) => {
  setCheckNick(e.target.checked);
  if (e.target.checked) {
    form.setFieldsValue({ patronymic: '' });
  }
  await form.validateFields(['patronymic']);
  localStorage.setItem('check', String(e.target.checked));
};

export const handleChangeCloseModal = (dispatch:any) => {
  dispatch(ModalVisible(false));
};

export const getEmailValue = ({ e, setConfirmEmailDisabled }: { e: React.ChangeEvent<HTMLInputElement>, setConfirmEmailDisabled(b:boolean): void }) => {
  if (e.target.value.match(emailValid)) {
    setConfirmEmailDisabled(false);
  }
  else {
    setConfirmEmailDisabled(true);
  }
};

export const onConfirmEmail = ({ form, _token, dispatch }: { form: any, _token:string, dispatch: Dispatch<any> }) => {
  const valueEmail = form.getFieldValue(['email']).toLowerCase();
  dispatch(requestSMSforEmail({ token: _token, email: valueEmail }));
};

export const onChangePhoneModalShow = ({ setEditPhoneModal, phoneConfirm, dispatch, _token, phoneValue } : IOnChangePhoneModalShow) => {
  if (!phoneConfirm) {
    dispatch(sendSmsCode({ _token, phoneValue }));
    return;
  }
  setEditPhoneModal(true);
};

export const clickOutsideClose = ({ e, setIsOptionsOpen }:{ e: any, setIsOptionsOpen(b:boolean): void }) => {
  if (e.relatedTarget?.name !== 'searchButton') {
    setIsOptionsOpen(false);
  }
  else {
    e.target.focus();
  }
};

export const CloseModalSuccessConfirm = (setSuccessConfirm: (b: boolean) => void) => {
  setSuccessConfirm(false);
};

export const CloseModalConfirmEmail = (dispatch:Dispatch<any>) => {
  dispatch(ModalVisibleForEmail(false));
};

export const onSearchAddressSelect = ({ value, setQueryState, setIsAddressChose }:IOnSearchAddressSelect) => {
  setIsAddressChose(false);
  if (value) {
    setQueryState(value);
  }
  else {
    setQueryState(null);
  }
};

export const handleKeyDown = ({ e, onSubmitAddressSelect }: { e: any; onSubmitAddressSelect: any }) => {
  if (e.key === 'Enter') {
    onSubmitAddressSelect(e);
  }
};

export const onKeyDownDatePicker = (e:any) => {
  if (e.code === 'Enter') {
    e.preventDefault();
  }
};

export const onFinishFailed = (errors: any) => {
  let el;
  if (errors) {
    const firstError = errors.errorFields[0].name[0];
    el = document.querySelector(`[name="${firstError}"]`);
  }
  else {
    el = document.querySelector('[name="name"]');
  }
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};

export const getInnValue = ({ value, dispatch, setInnValue }:{ value: string; dispatch: Dispatch<any>; setInnValue: (s:string) => void }) => {
  dispatch(validateInn(value));
  setInnValue(value);
};


export const handleKeyDownInn = ({ e, onSubmitInnSelect }:{ e: any; onSubmitInnSelect: any }) => {
  if (e.key === 'Enter') {
    onSubmitInnSelect(e);
  }
};


export const clickOutsideInnClose = ({ e, setIsOpenInn }:{ e: any; setIsOpenInn: (b:boolean) => void }) => {
  if (e.relatedTarget?.name !== 'searchButton') {
    setIsOpenInn(false);
  }
  else {
    e.target.focus();
  }
};

export const proctoringUrl = (
  currentTarget: EventTarget & HTMLImageElement,
  item: IDocImage,
  s3_token: string,
) => {
  if (currentTarget.dataset.failed === 'true') {
    return;
  }

  const newSrc1 = `${imgConfigurator}${item.image}?s3token=${s3_token}`;
  const newSrc2 = `${base_image_url}/${item.image}?s3token=${s3_token}`;

  if (currentTarget.src === newSrc1) {
    currentTarget.src = newSrc2;
  }
  else if (currentTarget.src === newSrc2) {
    currentTarget.src = '';
    currentTarget.dataset.failed = 'true';
  }
  else {
    currentTarget.src = newSrc1;
  }
};

export const documentUrl = (
  currentTarget: EventTarget & HTMLImageElement,
  item: string,
  s3_token: string,
) => {
  if (currentTarget.dataset.failed === 'true') {
    return;
  }

  const newSrc1 = `${proctoImgConfigurator}${item}?s3token=${s3_token}`;
  const newSrc2 = `${base_image_url}/${item}?s3token=${s3_token}`;

  if (currentTarget.src === newSrc1) {
    currentTarget.src = newSrc2;
  }
  else {
    currentTarget.src = '';
    currentTarget.dataset.failed = 'true';
  }
};
