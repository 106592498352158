import React, { FC } from 'react';
import { Button, Modal } from 'antd';
import { SquareWarning } from '../../../assets/icons/SquareWarning';
import { disconnectLeaderId } from '../../../store/actions/leaderIdActions';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { IConfirmUnbindLeaderId } from '../../../types/interfaces/interfaces';
import { cancel, unbind } from '../../../utils/const';
import styles from './ConfirmUnbindLeaderId.module.css';

export const ConfirmUnbindLeaderId:FC<IConfirmUnbindLeaderId> = ({ visible, setConfirmUnbind }) => {
  const { _token } = useAppSelector(({ profileSlice }) => profileSlice);
  const dispatch = useAppDispatch();

  const confirmUnbind = () => {
    setConfirmUnbind(false);
    dispatch(disconnectLeaderId(_token));
  };

  return (
    <Modal
      className={styles.confirmModal}
      centered={true}
      title={null}
      footer={null}
      open={visible}
      closable={false}
      onCancel={() => setConfirmUnbind(false)}
    >
      <div className={styles.confirmBody}>
        <SquareWarning />
        <h3 className={styles.reQuestion}>Вы уверены, что хотите отвязать LeaderID от профиля в UniOne</h3>

        <div className={styles.buttons}>
          <Button
            onClick={() => setConfirmUnbind(false)}
            type="ghost"
          >
            {cancel}
          </Button>
          <Button
            onClick={confirmUnbind}
            type="primary"
          >
            {unbind}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
