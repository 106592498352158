import React, { FC, useState } from 'react';
import { PinInput } from 'react-input-pin-code';
import { Button, Modal } from 'antd';
import { ModalVisibleForEmail } from '../../../store/profileSlice';
import { useAppDispatch } from '../../../store/store';
import { ICodeRequestEmail } from '../../../types/interfaces/interfaces';
import { brightRed, greenRgbColor, pastelBlue, PinContainerStyle, PinInputStyle } from '../../../utils/const';
import { CloseModalConfirmEmail } from '../../../utils/ProfileRegistration';
import { Counter } from '../CodeRequestPhone/Counter';
import styles from './CodeRequestEmail.module.css';


export const CodeRequestEmail: FC<ICodeRequestEmail> = ({ visible, confirmCode, confirmValue, setConfirmValue, modifiedEmail }) => {
  const [timer, setTimer] = useState<number>(120);

  const dispatch = useAppDispatch();

  const changeEmail = () => {
    dispatch(ModalVisibleForEmail(false));
  };

  return (
    <div>
      <Modal
        className={styles.modal}
        open={visible}
        onCancel={() => CloseModalConfirmEmail(dispatch)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={confirmCode}
            className={styles._modalBtnSave}
            disabled={confirmValue.join('').length < 4}
          >
            Отправить
          </Button>,
        ]}
      >
        <div style={{ width: '100%' }}>
          <h2 className={styles.title}>
            Изменить адрес электронной почты
          </h2>
          <div className={styles._modalBodyBlock}>
            <p className={styles.text}>
              Мы отправили код на почту <br /> <span className={styles.emailText}>{modifiedEmail}</span>
            </p>
            <div className={styles._modalBtnBlock}>
              <Button
                type="link"
                className={styles._fontSize20}
                onClick={changeEmail}
              >
                Изменить почту
              </Button>
            </div>

            <PinInput
              containerStyle={PinContainerStyle}
              inputStyle={PinInputStyle}
              autoFocus={true}
              placeholder={''}
              values={confirmValue}
              onChange={(value, index, onChangeConfirmValue) => setConfirmValue(onChangeConfirmValue)}
              validBorderColor={greenRgbColor}
              focusBorderColor={pastelBlue}
              errorBorderColor={brightRed}
            />

            <div className={styles.resend}>
              <Counter
                counter={timer}
                setCounter={setTimer}
                email={modifiedEmail}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
