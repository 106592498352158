import React, { useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { ModalVisibleForChangeEmail } from '../../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { InputStyle } from '../../../style/Input/InputStyle';
import { emailValid } from '../../../utils';
import { cancel, next } from '../../../utils/const';
import { getEmailValue, onConfirmEmail } from '../../../utils/ProfileRegistration';
import styles from './ChangeEmailModal.module.css';

export const ChangeEmailModal = ({ _token } : { _token:string }) => {
  const [form] = Form.useForm();
  const { _is_modal_visible_change_email } = useAppSelector(({ profileSlice }) => profileSlice);
  const dispatch = useAppDispatch();
  const [confirmEmailDisabled, setConfirmEmailDisabled] = useState<boolean>(true);

  const handleChangeEmailCancel = () => {
    dispatch(ModalVisibleForChangeEmail(false));
    form.resetFields(['email']);
  };

  return (
    <Modal
      forceRender
      className={styles.modal}
      open={_is_modal_visible_change_email}
      onCancel={handleChangeEmailCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          className={styles._modalBtnSave}
          disabled={confirmEmailDisabled}
          onClick={() => onConfirmEmail({ form, _token, dispatch })}
        >
          {next}
        </Button>,
        <Button
          type="text"
          key="back"
          className={styles._modalBtnCancel}
          onClick={handleChangeEmailCancel}
        >
          {cancel}
        </Button>,
      ]}
    >
      <div style={{ width: '100%' }}>
        <h2 className={styles.title}>
          Изменить адрес электронной почты
        </h2>
        <div className={styles.text}>
          Введите новый адрес электронной почты, на этот адрес будет отправлен код подтверждения
        </div>
        <Form form={form}>
          <Form.Item
            name="email"
            className={styles.input}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            rules={[
              {
                type: 'email',
                required: true,
                message: 'test@mail.ru',
                pattern: emailValid,
              },
            ]}
            hasFeedback
          >
            <InputStyle
              name="email"
              type="text"
              placeholder="Введите почту"
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => getEmailValue({ e, setConfirmEmailDisabled })}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
