import React, { FC, useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { Avatar, Button, Tooltip } from 'antd';
import cn from 'classnames';
import { AvatarIcon } from '../../assets/icons/AvatarIcon';
import { useAppSelector } from '../../store/store';
import { IsModalImage } from '../../style/IsModalImage/IsModalImage';
import { IAvatarComponent } from '../../types/interfaces/interfaces';
import { imgConfigurator, base_image_url } from '../../utils/const';
import styles from './Avatar.module.css';

export const AvatarComponent: FC<IAvatarComponent> = ({ isRegistration }) => {
  const [modal, setModal] = useState<boolean>(false);
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const { values, s3_token } = useAppSelector(({ profileSlice }) => profileSlice);

  useEffect(() => {
    if (values.avatar) {
      setAvatarUrl(`${imgConfigurator}${values.avatar}?s3token=${s3_token}`);
    }
  }, [values.avatar]);

  const onChoosePhoto = () => {
    setModal(true);
  };

  return (
    <>
      <div className={cn(styles.avatarEdit, { [styles.isVerify]: isRegistration })}>
        <Tooltip
          placement="bottom"
          title={values.avatar ? 'Обновить фотографию' : 'Загрузить фотографию'}
          color="#3B4168"
          overlayInnerStyle={{ marginTop: '8px' }}
        >
          <Avatar
            className={styles.avatar}
            size={{ xs: 136.8, sm: 136.8, md: 136.8, lg: 136.8, xl: 136.8, xxl: 136.8 }}
            icon={<Icon component={AvatarIcon} />}
            onClick={() => setModal(!modal)}
            src={avatarUrl}
            alt="avatar"
            onError={() => {
              setAvatarUrl(`${base_image_url}/${values.avatar}?s3token=${s3_token}`);
              return true;
            }}
          />
        </Tooltip>
        <IsModalImage modal={modal} setModal={setModal} avatarUrl={avatarUrl} />
      </div>
      { isRegistration && (
        <>
          <p className={styles.avatarText}>
            JPG или PNG. максимальный размер 5МБ
          </p>
          <Button
            type="default"
            className={styles.avatarBtn}
            onClick={onChoosePhoto}
          >
            Выберите фото
          </Button>
        </>
      )}
    </>
  );
};
