import React, { FC, useEffect, useRef, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { ErrorIcon } from '../../../../assets/icons/ErrorIcon';
import { WarningIcon } from '../../../../assets/icons/WarningIcon';
import { deleteDoc, editDoc, upload_doc } from '../../../../store/actions/documentActions';
import { get_users } from '../../../../store/actions/profileAction';
import { Diploma, Is_Load_But, Passport, Proctoring, Qualification } from '../../../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { IDocImage } from '../../../../types/entities/entitites';
import { IDeleteArguments, IEditModalArguments, IImagesForEdit } from '../../../../types/interfaces/interfaces';
import { errorsKey, mobileValide } from '../../../../utils';
import { proctoImgConfigurator } from '../../../../utils/const';
import { DocumentationEditModal } from '../../../Modal/DocumantationEditModal/DocumentationEditModal';
import { ProctoringModal } from '../../../Modal/ProctoringModal/ProctoringModal';
import { DocumentationCategory } from '../DocumentationCategory/DocumentationCategory';
import { ProctoringCard } from '../ProctoringCard/ProctoringCard';
import styles from './DocumentationEdit.module.css';


export const DocumentationEdit: FC<{ setIsDocumentationView: (b: boolean) => void }> = ({ setIsDocumentationView }) => {
  const dispatch = useAppDispatch();
  const {
    values,
    qualification,
    diploma,
    passport,
    _isLoadButton,
    proctoring,
    _token,
    s3_token,
  } = useAppSelector(({ profileSlice }) => profileSlice);

  const docs = {
    diploma,
    passport,
    qualification,
    proctoring,
  };
  const token = _token;
  const warningAlert = proctoring.filter((item: { type: any; }) => item.type);
  const msg_proct = values.user_info.docs.msg_proct;
  const [modal, setModal] = useState(false);
  const [proctoringModal, setProctoringModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileDescription, setFileDescription] = useState('');
  const [typeDoc, setTypeDoc] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [oldFile, setOldFile] = useState<any>('');
  const [isPdf, setIsPdf] = useState('');
  const [showImage, setShowImage] = useState<string>('');
  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);
  const [proctoringImg, setProctoringImg] = useState('');

  useEffect(() => {
    const data: IImagesForEdit = values.user_info.docs.images;
    const arrDiplom: IDocImage[] = [];
    const arrPassport: IDocImage[] = [];
    const arrQualification: IDocImage[] = [];
    const arrProctoring: IDocImage[] = [];
    if (data) {
      if (data.diploma) {
        data.diploma.forEach((item) => {
          arrDiplom.push(item);
        });
      }
      if (data.passport) {
        data.passport.forEach((item) => {
          arrPassport.push(item);
        });
      }
      if (data.qualification) {
        data.qualification.forEach((item) => {
          arrQualification.push(item);
        });
      }
      if (data.proctoring) {
        data.proctoring.forEach((item) => {
          arrProctoring.push(item);
        });
      }
    }
    dispatch(Diploma(arrDiplom));
    dispatch(Passport(arrPassport));
    dispatch(Qualification(arrQualification));
    dispatch(Proctoring(arrProctoring));
  }, [values]);

  const uploadProctoringImage = async() => {
    const response = await fetch(proctoringImg);
    const blob = await response.blob();
    closeVideo();
    try {
      dispatch(
        upload_doc({
          token,
          file: blob,
          type: 'proctoring',
          name: 'proctoring',
          description: 'fileDescription',
          diploma,
          passport,
          qualification,
          proctoring,
        }),
      );
      clearPhoto();
      setProctoringModal(false);
    }
    catch (e) {
      console.log(e);
    }
  };

  const getVideo = () => {
    let width = 640;
    let height = 480;

    if (mobileValide.test(navigator.userAgent)) {
      width = 480;
      height = 640;
    }

    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { ideal: width },
          height: { ideal: height },
          facingMode: 'user',
        },
        audio: false,
      })
      .then((stream) => {
        const video = videoRef.current;
        if (video) {
          video.setAttribute('autoplay', '');
          video.setAttribute('muted', '');
          video.setAttribute('playsinline', '');
          video.srcObject = stream;
          video.play();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const clearPhoto = () => {
    const photo = photoRef.current;
    if (photo) {
      const ctx = photo.getContext('2d');
      ctx?.clearRect(0, 0, photo.width, photo.height);
      setProctoringImg('');
    }
  };

  const closeVideo = () => {
    if (!videoRef.current?.srcObject) {
      return;
    }
    if ('getTracks' in videoRef.current.srcObject) {
      const video = videoRef.current.srcObject.getTracks();
      video.forEach((track: any) => track.stop());
      setImageSrc('');
      // clearInterval(faceInterval)
      clearPhoto();
    }
  };

  const openProctoringModal = () => {
    setProctoringModal(true);
    getVideo();
  };

  const closeProctoringModal = () => {
    setProctoringModal(false);
    closeVideo();
  };

  const checkFileInfo = (fileInfo: string) => fileInfo && fileInfo[0] !== ' ';

  const saveDocFile = () => {
    try {
      if (checkFileInfo(fileName) && checkFileInfo(fileDescription) && imageSrc) {
        if (editModal) {
          dispatch(
            editDoc({
              token,
              file: imageSrc,
              type: typeDoc,
              name: fileName,
              description: fileDescription,
              diploma,
              passport,
              qualification,
              oldFile,
            }),
          );
        }
        else {
          dispatch(
            upload_doc({
              token,
              file: imageSrc,
              type: typeDoc,
              name: fileName,
              description: fileDescription,
              diploma,
              passport,
              qualification,
              proctoring,
            }),
          );
        }
        setProctoringModal(false);
        setModal(false);
        clearStates();
      }
      else if (!fileName && fileDescription && imageSrc) {
        setErrorName(true);
      }
      else if (!fileDescription && fileName && imageSrc) {
        setErrorDescription(true);
      }
      else if (!imageSrc && fileDescription && fileName) {
        setErrorFile(true);
      }
      else {
        setErrorName(true);
        setErrorDescription(true);
        setErrorFile(true);
      }
    }
    catch (e) {
      if (!fileName) {
        const show = errorsKey(9999);
        message.error({
          className: 'messageError',
          content: show,
          icon: <ErrorIcon />,
        });
      }
      else {
        const show = errorsKey(8888);
        message.error({
          className: 'messageError',
          content: show,
          icon: <ErrorIcon />,
        });
      }
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  const onClose = () => {
    setModal(!modal);
    clearStates();
  };

  const clearStates = () => {
    setImageSrc('');
    setFileName('');
    setFileDescription('');
    setTypeDoc('');
    setIsPdf('');
    setShowImage('');
    setErrorName(false);
    setErrorDescription(false);
    dispatch(Is_Load_But(false));
  };

  const openUploadModal = (type: string) => {
    setModal(true);
    setTypeDoc(type);
    setEditModal(false);
  };

  const openEditModal = ({
    image,
    name,
    description,
    typeDoc,
  }: IEditModalArguments) => {
    setModal(true);
    setEditModal(true);

    if (typeof image === 'string') {
      setIsPdf(image.substring(image.length - 3, image.length));
    }

    if (isPdf !== 'pdf') {
      setShowImage(`${proctoImgConfigurator}${image}?s3token=${s3_token}`);
    }

    setFileName(name);
    setFileDescription(description);
    setOldFile(image);
    setTypeDoc(typeDoc);
  };

  const deleteDocs = ({ e, typeDoc, fileName }: IDeleteArguments) => {
    e.stopPropagation();
    dispatch(
      deleteDoc({
        token,
        type: typeDoc,
        name: fileName,
        diploma,
        passport,
        qualification,
        proctoring,
      }),
    );
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    e.preventDefault();
    e.stopPropagation();
    const [file] = e.target.files || e.dataTransfer.files;
    const value = e.target.value;
    if (file.size > 5 * 1024 * 1024) {
      e.target.value = '';
      onClose();
      return message.error({
        className: 'messageError',
        content: 'Размер файла больше 5мб',
        icon: <ErrorIcon />,
      });
    }
    setIsPdf(value.substring(value.length - 3, value.length));
    setImageSrc(file);
    uploadFile(file);
    setErrorFile(false);
  };

  const uploadFile = (file: File | Blob) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const fileRes = btoa(reader.result);
        const imageSrcValue = `data:image/jpg;base64,${fileRes}`;
        setShowImage(imageSrcValue);
      }
    };
    reader.onerror = () => {
      console.error('There is a problem while uploading...');
    };
  };

  const onChangeFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
    if (fileName) {
      setErrorName(false);
    }
  };

  const onChangeFileDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFileDescription(e.target.value);
    if (fileDescription) {
      setErrorDescription(false);
    }
  };

  const onCancelHandler = () => {
    setIsDocumentationView(true);
    dispatch(get_users(token));
  };

  return (
    <Row className={styles.documentation}>
      <Col span={24}>
        <Title
          level={4}
          className={styles.mainTitle}
        >
          Документы
        </Title>
      </Col>

      <DocumentationCategory
        title={'Паспорт'}
        openUploadModal={openUploadModal}
        openEditModal={openEditModal}
        deleteDocs={deleteDocs}
        docs={docs.passport}
        type={'passport'}
      />

      <DocumentationCategory
        title={'Диплом'}
        openUploadModal={openUploadModal}
        openEditModal={openEditModal}
        deleteDocs={deleteDocs}
        docs={docs.diploma}
        type={'diploma'}
      />

      <DocumentationCategory
        title={'Дополнительные документы'}
        openUploadModal={openUploadModal}
        openEditModal={openEditModal}
        deleteDocs={deleteDocs}
        docs={docs.qualification}
        type={'qualification'}
      />

      <Col span={24}>
        <div className={styles.documentationHeader}>
          <Title level={5} className={styles.title}>Фото для прокторинга</Title>
          <p className={styles.text}>
            Вам необходимо сделать 3 снимка. <b>Важно</b>, чтобы ваше лицо полностью попадало в кадр и на фото были только вы.
          </p>
          <p className={styles.proctoring_format}>Допустимые форматы: .jpg .png .gif Максимальный размер файла: 5 Мб</p>
        </div>

        <ProctoringCard
          proctoring={proctoring}
          openProctoringModal={openProctoringModal}
          deleteDocs={deleteDocs}
        />
        {
          proctoring.find((item: { type: null | string; }) => !item.type) && !!warningAlert.length && (
            <div className={styles.notVerify}>
              <Icon
                component={WarningIcon}
                className={styles.notVerifyIcon}
              />
              <p>
                Вам необходимо сделать еще {warningAlert.length === 1 ? ('два фото') : ('одно фото')}
                , чтобы данный раздел был заполнен. Либо удалите ранее сделанные снимки.
              </p>
            </div>
          )
        }
        {
          msg_proct && (
            <div className={styles.notVerify}>
              <p>
                {msg_proct}
              </p>
            </div>
          )
        }
      </Col>

      <Col span={24}>
        <Button
          className={styles.buttonCancel}
          type={'default'}
          onClick={onCancelHandler}
        >
          Назад
        </Button>
      </Col>

      <DocumentationEditModal
        editModal={editModal}
        modal={modal}
        onClose={onClose}
        saveDocFile={saveDocFile}
        _isLoadButton={_isLoadButton}
        handleUpload={handleUpload}
        isPdf={isPdf}
        showImage={showImage}
        errorName={errorName}
        onChangeFileName={onChangeFileName}
        fileName={fileName}
        errorDescription={errorDescription}
        errorFile={errorFile}
        onChangeFileDescription={onChangeFileDescription}
        fileDescription={fileDescription}
        oldFile={oldFile}
        s3_token={s3_token}
      />

      <ProctoringModal
        proctoringImg={proctoringImg}
        proctoringModal={proctoringModal}
        closeProctoringModal={closeProctoringModal}
        clearPhoto={clearPhoto}
        uploadProctoringImage={uploadProctoringImage}
        photoRef={photoRef}
        videoRef={videoRef}
        setProctoringImg={setProctoringImg}
      />
    </Row>
  );
};
