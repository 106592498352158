import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { PaperClipOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import Title from 'antd/es/typography/Title';
import { IDocCategory } from '../../../../types/interfaces/interfaces';
import { DocumentationList } from '../DocumentationList/DocumentationList';
import styles from './DocumentationCategory.module.css';

export const DocumentationCategory: FC<IDocCategory> = ({ title, openUploadModal, docs, openEditModal, deleteDocs, type }) => (
  <Col span={24} className={styles.documentBlock}>
    <div className={styles.documentHeader}>
      <Title level={5} className={styles.title}>{title}</Title>
      {
        type === 'qualification' && (
          <p className={styles.text}>
            Здесь можете загрузить удостоверение квалификации, ведомости аттестаций и прочее
          </p>
        )
      }
    </div>
    <Col>
      <label className={styles.inputLabel}>
        <div
          className={styles.dragAndDropBlock}
          onClick={() => openUploadModal(type)}
        >
          <p className={styles.descriptionFile}>
            <PaperClipOutlined className={styles.clipIcon} />
            <Link className={styles.link} to="#">
              {' '}
                Выберите файлы
            </Link>
          </p>
        </div>
      </label>
    </Col>

    <DocumentationList
      docs={docs}
      openEditModal={openEditModal}
      deleteDocs={deleteDocs}
      typeDoc={type}
    />
  </Col>
);
