import React, { FC, ReactElement, useEffect, useState } from 'react';
import { PinInput } from 'react-input-pin-code';
import { Button, Modal } from 'antd';
import { changePhoneProfile } from '../../../store/actions/profileAction';
import { ModalVisible } from '../../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { ICodeRequestPhone } from '../../../types/interfaces/interfaces';
import { cancel, PinContainerStyle, PinInputStyle, pastelBlue, brightRed, greenRgbColor } from '../../../utils/const';
import { regexObj } from '../../../utils/PhoneFilter';
import { handleChangeCloseModal } from '../../../utils/ProfileRegistration';
import styles from './CodeRequestPhone.module.css';
import { Counter } from './Counter';


export const CodeRequestPhone: FC<ICodeRequestPhone> = ({ visible, setEditPhoneModal, token }) => {
  const { _isLoadButton, modifiedPhone, acronym } = useAppSelector(({ profileSlice }) => profileSlice);
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState<number>(120);
  const [values, setValues] = useState<string[]>(new Array(4).fill(''));
  const [maskPhone, setMaskPhone] = useState<string>('');

  const changePhone = () => {
    dispatch(ModalVisible(false));
    setEditPhoneModal(true);
  };

  const confirmCode = () => {
    const confirmCodeVal = {
      code: values.join(''),
      token,
      phone: modifiedPhone,
    };
    dispatch(changePhoneProfile(confirmCodeVal));
    setValues(new Array(4).fill(''));
    setTimer(120);
  };

  const handleChangeOnCancel = () => {
    handleChangeCloseModal(dispatch);
    setTimer(120);
  };

  const phone: ReactElement = <span className={styles._phone}> {maskPhone} </span>;

  useEffect(() => {
    setMaskPhone(modifiedPhone.replace(regexObj[acronym].regex, regexObj[acronym].regexTransform));
  }, [modifiedPhone]);

  const btnDisable = (): boolean => values.join('').length <= 3;

  return (
    <div>
      <Modal
        centered={true}
        onCancel={handleChangeOnCancel}
        title={null}
        footer={null}
        open={visible}
        className={styles._confirmModal}
      >
        <div className={styles._body}>
          <h2 className={styles._modalTitle}>Изменить номер телефона</h2>
          <p className={styles._modalText}>
            Мы отправили код на номер {phone}
          </p>
          <div className={styles._modalBtnBlock}>
            <Button
              type="link"
              className={styles._fontSize20}
              onClick={changePhone}>
              Изменить номер
            </Button>
          </div>

          <PinInput
            containerStyle={PinContainerStyle}
            inputStyle={PinInputStyle}
            autoFocus={true}
            placeholder={''}
            values={values}
            onChange={(value, index, values) => setValues(values)}
            validBorderColor={greenRgbColor}
            focusBorderColor={pastelBlue}
            errorBorderColor={brightRed}
          />


          <div className={styles.resend}>
            <Counter
              counter={timer}
              setCounter={setTimer}
              phone={modifiedPhone}
            />
          </div>
          <div className={styles.navBtnBlock}>
            <Button
              disabled={btnDisable()}
              onClick={() => confirmCode()}
              type="primary"
              size={'large'}
              className={styles.styleButton}
              loading={_isLoadButton}
            >
              Отправить
            </Button>

            <Button
              onClick={handleChangeOnCancel}
              type="link"
              size="large"
              className={styles.cancelButton}
            >
              {cancel}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
