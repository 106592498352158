import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import { Col, Form, Radio, Row, Tooltip } from 'antd';
import MaskedInput from 'antd-mask-input';
import { RuleObject } from 'antd/es/form';
import { WarningIcon } from '../../../assets/icons/WarningIcon';
import { useAppSelector } from '../../../store/store';
import { InputStyle } from '../../../style/Input/InputStyle';
import { ISnilsForm } from '../../../types/interfaces/interfaces';
import { fioValid } from '../../../utils';
import styles from './SnilsForm.module.css';

export const SnilsForm: FC<ISnilsForm> = ({
  checkCitizen,
  setCheckCitizen,
  getSnilsValue,
  checkCitizenChange,
  form,
  HOCMasked,
  militaryUniversities,
  openConfirmMilitaryUniversitiesModal,
  citizenRf,
  checkCitizenRf,
}) => {
  const { errors } = useAppSelector(({ profileSlice }) => profileSlice);

  const validateSnils = (rule: RuleObject, value: string) => {
    if (value && errors) {
      return Promise.reject(errors);
    }
    if (value) {
      return Promise.resolve('okay');
    }
    return Promise.resolve('okay');
  };

  return (
    <div>
      <Row>
        <Col span={24} className={styles.hocMaskedError}>
          <div className={styles.checkboxes}>
            <div className={styles.checkBox}>
              <Radio
                checked={citizenRf}
                onChange={checkCitizenRf}
              >
                Гражданин РФ
              </Radio>
            </div>
            <div className={styles.checkBox}>
              <Radio
                checked={checkCitizen}
                onChange={(e) => checkCitizenChange({ e, setCheckCitizen, form })}
              >
                Не являюсь гражданином РФ
              </Radio>
            </div>
            <div className={styles.checkBox}>
              <Radio
                checked={militaryUniversities}
                onChange={(e) => openConfirmMilitaryUniversitiesModal(e)}
              >
                Студент военного ВУЗа
              </Radio>
            </div>
          </div>
        </Col>
      </Row>

      {
        citizenRf &&
        (<Col span={24} className={styles.snilsBlock}>
          <Tooltip
            title="Данное поле может быть обязательным для отдельных сервисов платформы"
            color="#3B4168"
            placement="bottom"
            overlayInnerStyle={{ textAlign: 'center' }}
          >
            <Icon
              component={WarningIcon}
              className={styles.warningIcon}
            />
          </Tooltip>
          <Form.Item
            hasFeedback
            rules={[
              { required: false, message: 'Введите СНИЛС' },
              { validator: validateSnils },
            ]}
            label="СНИЛС"
            name="snils"
          >
            <HOCMasked.Input
              name="snils"
              mask={'000-000-000 00'}
              size="20"
              allowClear
              type="text"
              placeholder="ХХХ-ХХХ-ХХХ YY"
              disabled={checkCitizen || militaryUniversities}
              onChange={getSnilsValue}
              onFocus={getSnilsValue}
            />
          </Form.Item>
        </Col>
        )
      }

      {checkCitizen && (
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              rules={[
                { message: 'Вы можете использовать кириллицу', pattern: fioValid },
                { required: true, message: 'Введите наименование документа' },
                { max: 40, message: 'Поле не может содержать более 40 символов' },
              ]}
              label="Наименование документа"
              name="idDocName"
            >
              <InputStyle
                name="idDocName"
                type="text"
                placeholder="Введите имя документа"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              rules={[
                { required: true, message: 'Введите номер документа' },
                { max: 40, message: 'Поле не может содержать более 40 символов' },
              ]}
              label="Номер документа"
              name="idDoc"
            >
              <InputStyle
                name="idDoc"
                type="text"
                placeholder="Введите номер документа"
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      {militaryUniversities && (
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              rules={[
                { required: true, message: 'Введите дополнительный СНИЛС' },
              ]}
              label="Дополнительный СНИЛС"
              name="snils_dop"
            >
              <MaskedInput
                name="snils_dop"
                mask={'ВВВ-000-000 00'}
                size="middle"
                allowClear
                type="text"
                placeholder="ВВВ-ХХХ-ХХХ YY"
              />
            </Form.Item>
          </Col>
        </Row>
      )}

    </div>
  );
};
