import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import Text from 'antd/es/typography/Text';
import { requestSMS, requestSMSforEmail } from '../../../store/actions/profileAction';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { ICounter } from '../../../types/interfaces/interfaces';
import styles from './CodeRequestPhone.module.css';

export const Counter: FC<ICounter> = ({ counter, setCounter, phone, email }) => {
  const dispatch = useAppDispatch();
  const { _token } = useAppSelector(({ profileSlice }) => profileSlice);

  const padTime = (time:number) => (String(time).length === 1 ? `0${time}` : `${time}`);

  const format = (time:number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${padTime(seconds)}`;
  };

  useEffect(() => {
    let timer:ReturnType<typeof setTimeout> | null = null;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const sendSmsConfirm = () => {
    setCounter(120);
    if (email) {
      dispatch(requestSMSforEmail({ token: _token, email }));
      return;
    }
    dispatch(requestSMS({ phone, token: _token }));
  };

  return (
    <div className={styles.counterBlock}>
      <Text type="secondary">
        <Button
          type="link"
          className={`${styles.resendBtn} ${counter === 0 && styles.resendBtnActive}`}
          onClick={sendSmsConfirm}
          disabled={counter !== 0}
        >
          Выслать повторно
        </Button>
        через {format(counter)} секунд
      </Text>
    </div>
  );
};
