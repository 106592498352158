import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorIcon } from '../../assets/icons/ErrorIcon';
import { SuccessIcon } from '../../assets/icons/SuccessIcon';
import { ProfileService } from '../../services/profileService';
import { IConfirmTelegramCode, ITelegramBind } from '../../types/interfaces/interfaces';
import { BindTelegramModalVisible, changeTelegramBind, ConfirmTelegramModalVisible } from '../profileSlice';

export const telegramBind = createAsyncThunk(
  'profileSlice/telegramBind',
  async(data: ITelegramBind, { dispatch }) => {
    const value = JSON.stringify(data);
    const response = await ProfileService.$telegramBind(value);
    if (response?.data.status) {
      message.success({
        className: 'messageSuccess',
        content: 'Введите код из Telegram',
        icon: <SuccessIcon />,
      });
      dispatch(BindTelegramModalVisible(false));
      dispatch(ConfirmTelegramModalVisible(true));
    }
    else {
      message.error({
        className: 'messageError',
        content: response?.data.errors[0].message,
        icon: <ErrorIcon />,
      });
    }
  },
);

export const confirmTelegramCode = createAsyncThunk(
  'profileSlice/confirmTelegram',
  async(data: IConfirmTelegramCode, { dispatch }) => {
    const value = JSON.stringify(data);
    const response = await ProfileService.$confirmTelegramCode(value);
    if (response?.data.status) {
      message.success({
        className: 'messageSuccess',
        content: 'Успешно привязан Telegram',
        icon: <SuccessIcon />,
      });
      dispatch(ConfirmTelegramModalVisible(false));
      dispatch(changeTelegramBind(true));
    }
    else {
      message.error({
        className: 'messageError',
        content: response?.data.errors[0].message,
        icon: <ErrorIcon />,
      });
    }
  },
);
