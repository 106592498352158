import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorIcon } from '../../assets/icons/ErrorIcon';
import { SuccessIcon } from '../../assets/icons/SuccessIcon';
import { ProfileService } from '../../services/profileService';
import { IEsiaBindRequest } from '../../types/interfaces/interfaces';
import { errorsKey } from '../../utils';
import { changeEsiaStatus } from '../profileSlice';

// Проверка кода есиа и дальнейшая привязка пользователя
export const esiaBind = createAsyncThunk(
  'profileSlice/esiaBind',
  async(data: IEsiaBindRequest, { dispatch }) => {
    const value = JSON.stringify(data);
    const response = await ProfileService.$esiaBind(value);
    if (response?.data.status) {
      dispatch(changeEsiaStatus(true));
      message.success({
        className: 'messageSuccess',
        content: 'Вы успешно привязали ЕСИА',
        icon: <SuccessIcon />,
      });
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);

// Отвязка esia id от юзера
export const esiaUnbind = createAsyncThunk(
  'profileSlice/esiaUnbind',
  async(token: string, { dispatch }): Promise<void> => {
    const value = JSON.stringify({ token });
    const response = await ProfileService.$esiaUnbind(value);
    if (response?.data.status) {
      dispatch(changeEsiaStatus(false));
      message.success({
        className: 'messageSuccess',
        content: 'Вы отвязали свой аккаунт от ЕСИА',
        icon: <SuccessIcon />,
      });
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);

// Получение ссылки на вход в есиа
export const getEsiaLoginLink = createAsyncThunk(
  'profileSlice/bindPublicServices',
  async(): Promise<void> => {
    const response = await ProfileService.$getEsiaLoginLink();
    if (response?.data.status) {
      window.location.href = response.data.values[0];
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);
