import React, { FC, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Main } from '../pages/Main';
import { NotFound } from '../pages/NotFound';
import { Profile } from '../pages/Profile';
import { Registration } from '../pages/Registration';
import { esiaBind } from '../store/actions/esiaActions';
import { callbackLeaderId } from '../store/actions/leaderIdActions';
import { Url_Put } from '../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../store/store';

export const Routers: FC = () => {
  const { values, _token } = useAppSelector(({ profileSlice }) => profileSlice);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const param = location.search;

  const searchParams = new URLSearchParams(location.search);

  const code = searchParams.get('code');
  const state = searchParams.get('state');

  useEffect(() => {
    if (code && _token) {
      if (state) {
        dispatch(esiaBind({ token: _token, code, state }));
      }
      else {
        dispatch(callbackLeaderId({ uri_code: window.location.href, token: _token }));
      }
    }
  }, [_token, dispatch, code, state]);

  useEffect(() => {
    const redirect = searchParams.get('redirect');
    dispatch(Url_Put({ redirect }));
    navigate(values.user_info.passport.name || null ? `/profile${param}` : `/registration${param}`);
  }, [values.user_info.passport.name]);

  return (
    <Routes>
      <Route path={'/'} element={<Main />}>
        <Route path={'registration'} element={<Registration />} />
        <Route path={'profile'} element={<Profile />} />
        <Route path={'*'} element={<NotFound />} />
      </Route>
    </Routes>
  );
};
