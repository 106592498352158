import React, { FC, useState } from 'react';
import { Button, Checkbox, Col, Modal, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IConfirmRegistrationData } from '../../../types/interfaces/interfaces';
import { phoneMask } from '../../../utils/PhoneFilter';
import styles from './ConfirmRegistrationData.module.css';

export const ConfirmRegistrationData: FC<IConfirmRegistrationData> = ({
  form,
  isConfirmRegistrationData,
  setIsConfirmRegistrationData,
  sendRegistrationData,
}) => {
  const [confirmDataDisabled, setConfirmDataDisabled] = useState<boolean>(true);
  const user = form.getFieldsValue();
  const patronymic = user.patronymic || '';
  const userFullName = `${user.sur_name} ${user.name} ${patronymic}`;
  const userPhone = phoneMask(user.phone);

  const onConfirmData = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setConfirmDataDisabled(false);
    }
    else {
      setConfirmDataDisabled(true);
    }
  };

  return (
    <div>
      <Modal
        className={styles.modal}
        open={isConfirmRegistrationData}
        width={796}
        bodyStyle={{
          paddingTop: 32,
          paddingRight: 32,
          paddingBottom: 0,
          paddingLeft: 32,
        }}
        onCancel={() => setIsConfirmRegistrationData(false)}
        footer={[
          <Button
            type="default"
            key="back"
            onClick={() => setIsConfirmRegistrationData(false)}
          >
            Изменить данные
          </Button>,
          <Button
            type="primary"
            key="submit"
            disabled={confirmDataDisabled}
            onClick={() => sendRegistrationData(form.getFieldsValue())}
          >
            Подтвердить данные
          </Button>,
        ]}
      >
        <div className={styles.body}>
          <h2 className={styles.title}>Подтвердить данные</h2>
          <div className={styles.line} />
          <h4 className={styles.minorTitle}>
            Подтвердите правильность указанных ниже данных
          </h4>
          <p className={styles.description}>
            На основании этих данных будут сформированы документы по итогам обучения или прохождения оценки компетенций
          </p>
          <div className={styles.userInfoBlock}>
            <Row gutter={[32, 24]}>
              <Col sm={24} md={12}>
                <div className={styles.userInfo}>
                  <p className={styles.userInfoLabel}>ФИО</p>
                  <p className={styles.userInfoValue}>{userFullName}</p>
                </div>
              </Col>
              <Col sm={24} md={12}>
                {user.snils
                  ? (
                    <div className={styles.userInfo}>
                      <p className={styles.userInfoLabel}>СНИЛС</p>
                      <p className={styles.userInfoValue}>{user.snils}</p>
                    </div>
                  )
                  : (
                    <div className={styles.userInfo}>
                      <p className={styles.userInfoLabel}>{user.idDocName}</p>
                      <p className={styles.userInfoValue}>{user.idDoc}</p>
                    </div>
                  )}
              </Col>
              <Col sm={24} md={12}>
                <div className={styles.userInfo}>
                  <p className={styles.userInfoLabel}>Телефон</p>
                  <p className={styles.userInfoValue}>{userPhone}</p>
                </div>
              </Col>
              <Col sm={24} md={12}>
                <div className={styles.userInfo}>
                  <p className={styles.userInfoLabel}>e-mail</p>
                  <p className={styles.userInfoValue}>{user.email}</p>
                </div>
              </Col>
              <Col span={24}>
                <div className={styles.userInfo}>
                  <p className={styles.userInfoLabel}>Адрес</p>
                  <p className={styles.userInfoValue}>{user.address}</p>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              <Checkbox
                className={styles.checkbox}
                onChange={onConfirmData}
              >
                Соглашаюсь с тем, что предоставленные данные являются верными и информирован,
                что на основании этих данных будут сформированы документы по итогам обучения
                или прохождения оценки компетенций
              </Checkbox>
            </Col>
          </Row>
          <div className={styles.line} />
        </div>
      </Modal>
    </div>
  );
};
