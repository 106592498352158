import React, { FC } from 'react';
import { FileZipOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { useAppSelector } from '../../../../store/store';
import {
  base_image_url,
  proctoImgConfigurator,
} from '../../../../utils/const';
import { proctoringUrl } from '../../../../utils/ProfileRegistration';
import styles from './DocumentationView.module.css';

export const DocumentationView: FC<{
  setIsDocumentationView: (b: boolean) => void;
}> = ({ setIsDocumentationView }) => {
  const { values, s3_token } = useAppSelector(({ profileSlice }) => profileSlice);
  const docs = values.user_info.docs.images;

  const checkImage = (src: string) => {
    const img = new Image();
    img.src = src;
    return img.complete && img.naturalWidth !== 0;
  };

  return (
    <Row className={styles.documentation}>
      <Col span={24}>
        <Title level={4} className={styles.mainTitle}>
          Документы
        </Title>
      </Col>
      <Col span={24} className={styles.documentBlock}>
        <Title className={styles.title} level={5}>
          Паспорт
        </Title>

        <Row>
          {docs.passport &&
            docs.passport.map((item) => (
              <Col className={styles.docs} key={String(item.image)}>
                <Row justify={'space-between'}>
                  <div className={styles.file}>
                    <FileZipOutlined className={styles.fileIcon} />
                    {
                      checkImage(`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`) ? (
                        <a
                          href={`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <h4 className={styles.fileText}>{item.name}</h4>
                        </a>
                      ) : (
                        <a
                          href={`${base_image_url}/${item.image}?s3token=${s3_token}`}
                        >
                          <h4 className={styles.fileText}>{item.name}</h4>
                        </a>
                      )
                    }
                  </div>
                </Row>
              </Col>
            ))}
        </Row>
      </Col>

      <Col span={24} className={styles.documentBlock}>
        <Title className={styles.title} level={5}>
          Диплом
        </Title>

        <Row>
          {docs.diploma &&
            docs.diploma.map((item) => (
              <Col className={styles.docs} key={String(item.image)}>
                <Row
                  justify={'space-between'}
                  className={styles.margin_left_right}
                >
                  <div className={styles.file}>
                    <FileZipOutlined className={styles.fileIcon} />
                    {
                      checkImage(`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`) ? (
                        <a
                          href={`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`}
                        >
                          <h4 className={styles.fileText}>{item.name}</h4>
                        </a>
                      ) : (
                        <a
                          href={`${base_image_url}/${item.image}?s3token=${s3_token}`}>
                          <h4 className={styles.fileText}>{item.name}</h4>
                        </a>
                      )
                    }
                  </div>
                </Row>
              </Col>
            ))}
        </Row>
      </Col>

      <Col span={24} className={styles.documentBlock}>
        <Title className={styles.title} level={5}>
          Дополнительные документы
        </Title>

        <Row>
          {docs.qualification &&
            docs.qualification.map((item) => (
              <Col className={styles.docs} key={String(item.image)}>
                <Row justify={'space-between'}>
                  <div className={styles.file}>
                    <FileZipOutlined className={styles.fileIcon} />
                    {
                      checkImage(`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`) ? (
                        <a href={`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`}>
                          <h4 className={styles.fileText}>{item.name}</h4>
                        </a>
                      ) : (
                        <a href={`${base_image_url}/${item.image}?s3token=${s3_token}`}>
                          <h4 className={styles.fileText}>{item.name}</h4>
                        </a>
                      )
                    }
                  </div>
                </Row>
              </Col>
            ))}
        </Row>
      </Col>

      <Col span={24} className={styles.documentBlock}>
        <Title className={styles.title} level={5}>
          Фото для прокторинга
        </Title>
        <Col className={styles.proctoringCards}>
          {docs.proctoring &&
            docs.proctoring.map((item, index) => (
              <div className={styles.proctoringCard} key={index}>
                <img
                  className={styles.proctoringImage}
                  src={`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`}
                  alt=""
                  onError={({ currentTarget }) => proctoringUrl(currentTarget, item, s3_token)}
                />
                <span className={styles.proctoringPhotoCounter}>
                  {`Фото ${index + 1}`}
                </span>
              </div>
            ))}
        </Col>
      </Col>

      <Button type="default" onClick={() => setIsDocumentationView(false)}>
        Изменить данные
      </Button>
    </Row>
  );
};
