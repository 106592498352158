import { IGeoValue, IUsersData } from '../responses/responses';

export enum AcronymEnum {
  rf = 'RF',
  kz = 'KZ',
  by = 'BY',
  kg = 'KG',
  uz = 'UZ',
  am = 'AM',
  mn = 'MN',
  cn = 'CN',
}

export interface IInitialState {
  values: IValues | IUsersData;
  errors: string;
  _token: string;
  s3_token: string;
  _IsLoading: boolean;
  _is_modal_visible: boolean;
  _isLoadButton: boolean;
  _IsLoading_Admin: boolean;
  _url: IRedirect;
  _progress: number | string;
  _progressCount: number | string;
  address: IGeoValue[];
  _is_modal_visible_email: boolean;
  _is_modal_visible_change_email: boolean;
  _is_modal_visible_phone: boolean;
  _is_confirm_telegram_modal_visible: boolean;
  _is_bind_telegram_modal_visible: boolean;
  diploma: IDocImage[];
  passport: IDocImage[];
  qualification: IDocImage[];
  proctoring: IDocImage[] | any;
  modifiedPhone: string;
  modifiedEmail: string;
  result_valid_inn: boolean;
  errors_inn: string;
  inn: any[];
  need_fields?: boolean | null;
  emailConfirm: boolean;
  phoneConfirm: boolean;
  acronym: AcronymEnum;
}

export interface IErrors {
  key: number;
  message: string
}

export interface IRedirect {
  redirect: any;
}

export interface IGeo {
  country: string;
  city: string;
  fias_addr: string;
  fias_id: string
}

export interface IWork {
  place: string;
  industry: string;
  position: string;
  inn: string;
  ogrn?: string;
}

export interface IBirthday {
  year: number | null;
  month: number | null;
  day: number | null;
}

export interface IPassport {
  name: string;
  sur_name: string;
  patronymic: string;
}

export interface IUserInfo {
  geo: IGeo;
  work: IWork;
  birthday: IBirthday;
  passport: IPassport;
  docs: IImages
}

export interface IModeration {
  is_moderated: boolean;
  comment?: string;
  moderation_time?: string;
}

interface IConfirmValue {
  email: boolean;
  phone: boolean;
  telegram: boolean;
}


export interface IValues {
  id: string;
  email: string;
  phone: string;
  login: string;
  status: string;
  avatar: string;
  sex: string;
  has_pass: boolean;
  confirm: IConfirmValue;
  moderation: IModeration;
  user_info: IUserInfo;
  is_leader_id: boolean;
  is_esia: boolean;
}

export interface IConfirm {
  email: boolean;
  email_code: string;
  email_code_tm: string;
  phone: boolean;
  phone_code: string;
  phone_code_tm: string;
  telegram: boolean;
  telegram_code_tm: string;
}

export interface IDocImage {
  description: string;
  image?: Blob | File | string;
  name: string;
  type: string;
  oldName?: Blob | File;
}

export interface IPayloadDocs {
  description: string;
  name: string;
  type: string;
  image: string[]
}

interface IImageArray {
  diploma: IDocImage[] | null;
  passport: IDocImage[] | null;
  proctoring: IDocImage[] | null;
  qualification: IDocImage[] | null;
}

export interface IImages {
  images: IImageArray;
  id_doc:string;
  id_doc_name:string;
  snils: string;
  snils_dop: string;
  msg_proct: string;
}
