import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import { RuleObject } from 'antd/es/form';
import { checkPassword } from '../../../store/actions/profileAction';
import { useAppDispatch } from '../../../store/store';
import InputPasswordProfile from '../../../style/InputPasswordProfile';
import ProgressStyle from '../../../style/Progress/ProgressStyle';
import { passValid } from '../../../utils';
import { passwordCondition } from '../../../utils/const';
import styles from './ChangePasswordForm.module.css';


export const ChangePasswordForm: FC = () => {
  const dispatch = useAppDispatch();

  const passwordRules = [
    {
      validator(_: RuleObject, value: string) {
        if (value && value.length < 5) {
          return Promise.reject(
            'Пароль должен содержать не менее 5 символов',
          );
        }
        if (value && !value.match(passValid)) {
          return Promise.reject(passwordCondition);
        }
        value && dispatch(checkPassword(value));
        return Promise.resolve(true);
      },
    },
  ];

  const onEnterPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(checkPassword(e.target.value));
  };


  return (
    <Row
      className={styles.password}
    >
      <Col xs={24} sm={12}>
        <Form.Item
          hasFeedback
          label="Придумайте новый пароль"
          name="password"
          className={styles.passwordInput}
          rules={passwordRules}
        >
          <InputPasswordProfile
            type="text"
            placeholder="Введите пароль"
            onChange={onEnterPassword}
          />
        </Form.Item>
        <p className={styles.text}>
          Пароль должен состоять минимум из 5 символов и содержать цифры и специальные символы (! “ # $ % ‘ () *)
        </p>
      </Col>

      <Col xs={24} sm={10} md={6} className={styles.progressBottom}>
        <ProgressStyle isChangePasswordForm={true} />
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          dependencies={['password']}
          hasFeedback
          label="Повторите пароль"
          name="confirmPassword"
          rules={[
            { required: false, message: 'пожалуйста введи пароль!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                if (getFieldValue('password') === '') {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('пароли не совпадают'));
              },
            }),
          ]}
        >
          <InputPasswordProfile
            type="text"
            placeholder="Подтвердите пароль"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
