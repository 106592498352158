import React, { FC } from 'react';
import { DeleteOutlined, EditOutlined, FileZipOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useAppSelector } from '../../../../store/store';
import { IDocList } from '../../../../types/interfaces/interfaces';
import { base_image_url, proctoImgConfigurator } from '../../../../utils/const';
import styles from './DocumentationList.module.css';

export const DocumentationList: FC<IDocList> = ({ docs, openEditModal, deleteDocs, typeDoc }) => {
  const { s3_token } = useAppSelector(({ profileSlice }) => profileSlice);

  const checkImage = (src: string) => {
    const img = new Image();
    img.src = src;
    return img.complete && img.naturalWidth !== 0;
  };

  return (
    <Row>
      {docs &&
        docs.map((item) => (
          <Col className={styles.docs} key={String(item.image)}>
            <Row justify={'space-between'}>
              <div className={styles.file}>
                <FileZipOutlined className={styles.fileIcon} />
                {
                  checkImage(`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`) ? (
                    <a href={`${proctoImgConfigurator}${item.image}?s3token=${s3_token}`}>
                      <h4 className={styles.fileText}>{item.name}</h4>
                    </a>
                  ) : (
                    <a href={`${base_image_url}/${item.image}?s3token=${s3_token}`}>
                      <h4 className={styles.fileText}>{item.name}</h4>
                    </a>
                  )
                }
              </div>
              <div>
                <EditOutlined
                  className={styles.editIcon}
                  onClick={() => openEditModal({
                    image: item.image,
                    name: item.name,
                    description: item.description,
                    typeDoc,
                  })
                  }
                />
                <DeleteOutlined
                  className={styles.deleteIcon}
                  onClick={(e) => deleteDocs({ e, typeDoc: item.type, fileName: String(item.image) })}
                />
              </div>
            </Row>
          </Col>
        ))}
    </Row>
  );
};
