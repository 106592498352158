import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import { checkPassword } from '../../../store/actions/profileAction';
import { useAppDispatch } from '../../../store/store';
import InputPasswordProfile from '../../../style/InputPasswordProfile';
import ProgressStyle from '../../../style/Progress/ProgressStyle';
import { passValid } from '../../../utils';
import { passwordCondition } from '../../../utils/const';
import styles from './PasswordForm.module.css';

export const PasswordForm: FC = () => {
  const dispatch = useAppDispatch();
  return (
    <Col>
      <p className={styles.description}>
        Вы можете создать постоянный пароль для входа в систему.<br />
        {passwordCondition}
      </p>
      <Row gutter={[24, 24]}>
        <Col sm={24} md={12} className={styles.password}>
          <Form.Item
            hasFeedback
            label="Придумайте пароль"
            name="password"
            rules={[
              {
                validator(_, value) {
                  if (value && value.length < 5) {
                    return Promise.reject(
                      'Пароль должен содержать не менее 5 символов',
                    );
                  }
                  if (value && !value.match(passValid)) {
                    return Promise.reject(passwordCondition);
                  }
                  value && dispatch(checkPassword(value));
                  return Promise.resolve(true);
                },
              },
            ]}
          >
            <InputPasswordProfile
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(checkPassword(e.target.value))}
              type="text"
              placeholder="Введите пароль"
            />
          </Form.Item>

          <ProgressStyle />
        </Col>

        <Col sm={24} md={12}>
          <Form.Item
            dependencies={['password']}
            rules={[
              { required: false, message: 'пожалуйста введи пароль!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  if (getFieldValue('password') === '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('пароли не совпадают'));
                },
              }),
            ]}
            hasFeedback
            label="Повторите пароль"
            name="confirmPassword"
          >
            <InputPasswordProfile
              type="text"
              placeholder="Подтвердите пароль"
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
};
