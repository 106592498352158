import React, { FC, useState } from 'react';
import { DocumentationEdit } from './DocumentationEdit/DocumentationEdit';
import { DocumentationView } from './DocumentationView/DocumentationView';

export const Documentation: FC = () => {
  const [isDocumentationView, setIsDocumentationView] = useState<boolean>(true);

  return (
    <div>
      {isDocumentationView ? (
        <DocumentationView setIsDocumentationView={setIsDocumentationView} />
      ) : (
        <DocumentationEdit setIsDocumentationView={setIsDocumentationView} />
      )}
    </div>
  );
};
