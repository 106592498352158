import React, { FC, useState } from 'react';
import { PinInput } from 'react-input-pin-code';
import { Button, message, Modal } from 'antd';
import { ErrorIcon } from '../../../assets/icons/ErrorIcon';
import { IConfirmMilitary } from '../../../types/interfaces/interfaces';
import { military_university_code } from '../../../utils/const';
import styles from '../CodeRequestPhone/CodeRequestPhone.module.css';

export const ConfirmMilitaryUniversities: FC<IConfirmMilitary> = ({
  openConfirmMilitaryUniversities,
  setOpenConfirmMilitaryUniversities,
  setMilitaryUniversities,
  setCheckCitizen,
  setCitizenRf,
}) => {
  const [confirmValue, setConfirmValue] = useState(new Array(4).fill(''));

  const handleChangeOnCancel = () => {
    setMilitaryUniversities(false);
    setOpenConfirmMilitaryUniversities(false);
  };

  const confirmCode = () => {
    if (confirmValue.join('') === military_university_code) {
      setMilitaryUniversities(true);
      setOpenConfirmMilitaryUniversities(false);
      setCheckCitizen(false);
      setCitizenRf(false);
    }
    else {
      message.error({
        className: 'messageError',
        content: 'Неверный код',
        icon: <ErrorIcon />,
      });
      setOpenConfirmMilitaryUniversities(false);
    }
  };

  return (
    <Modal
      className={styles.ConfirmMilitaryUniversities}
      open={openConfirmMilitaryUniversities}
      onCancel={handleChangeOnCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={confirmCode}
          className={styles._modalBtnSave}
          disabled={confirmValue.join('').length < 4}
        >
          Отправить
        </Button>,
      ]}
    >
      <div style={{ width: '100%' }}>
        <h2>Подтверждение кода</h2>
        <div>
          <PinInput
            inputStyle={{
              background: 'white',
              border: '1px',
              fontSize: 23,
              height: 35,
              width: 36,
              boxShadow: 'inset .5px 2px 6px 2px rgba(170,170,170,100)',
            }}
            autoFocus={true}
            placeholder={''}
            values={confirmValue}
            onChange={(value, index, onChangeConfirmValue) => setConfirmValue(onChangeConfirmValue)}
          />
        </div>
      </div>
    </Modal>
  );
};
