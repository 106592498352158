import React, { FC } from 'react';
import { Checkbox } from 'antd';
import { ICheckBox } from '../../types/interfaces/interfaces';
import style from './Checkbox.module.css';

const CheckboxStyle: FC<ICheckBox> = ({ children, checked, onChange, isRadio }) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return isRadio ? (
    <span className={style.parentCheckbox} onKeyDown={handleKeyDown}>
      <Checkbox checked={checked} onChange={onChange}>{children}</Checkbox>
    </span>
  ) : (
    <Checkbox checked={checked} onChange={onChange} onKeyDown={handleKeyDown}>
      {children}
    </Checkbox>
  );
};

export default CheckboxStyle;
