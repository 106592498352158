import React, { ChangeEvent, FC, useCallback, useEffect, useState, DragEvent } from 'react';
import Cropper from 'react-easy-crop';
import Icon from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import Text from 'antd/es/typography/Text';
import { ErrorIcon } from '../../assets/icons/ErrorIcon';
import { LoadAvatarIcon } from '../../assets/icons/LoadAvatarIcon';
import { upload_file } from '../../store/actions/profileAction';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { ICroppedAreaPixels, IIsModalImage } from '../../types/interfaces/interfaces';
import { errorsKey } from '../../utils';
import { getCroppedImg } from '../../utils/canvasUtils';
import { cancel, save } from '../../utils/const';
import styles from './IsModalImage.module.css';


export const IsModalImage:FC<IIsModalImage> = ({ modal, setModal, avatarUrl }) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<ICroppedAreaPixels | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAvatarUpload, setIsAvatarUpload] = useState<boolean>(false);
  const token = useAppSelector(({ profileSlice }) => profileSlice._token);
  const dispatch = useAppDispatch();
  const fileInput = document.querySelector('input[type="file"]');

  const onCropComplete = useCallback((croppedArea: ICroppedAreaPixels, croppedAreaPixels : ICroppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async() => {
    setLoading(true);
    try {
      const croppedImageFile = await getCroppedImg({ imageSrc, pixelCrop: croppedAreaPixels });
      const cropImg = String(croppedImageFile);
      dispatch(upload_file({ token, file: cropImg }));

      setTimeout(() => {
        setModal(false);
        setLoading(false);
      }, 1000);
    }
    catch (e) {
      const show = errorsKey(8888);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
      setTimeout(() => {
        closeModal();
      }, 1000);
    }
  }, [imageSrc, croppedAreaPixels]);

  const handleUpload = (e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    const size = (target.files![0].size / 1024 / 1024).toFixed(2);
    if (+size < 5) {
      e.preventDefault();
      e.stopPropagation();

      const file = target.files![0] || (e as DragEvent<HTMLInputElement>).dataTransfer.files?.[0];

      uploadFile(file);
    }
    else {
      if (fileInput instanceof HTMLInputElement) {
        fileInput.value = '';
      }
      message.error({
        className: 'messageError',
        content: 'Слишком большой файл. Загружайте изображение меньше 5МБ',
        icon: <ErrorIcon />,
      });
    }
  };

  const uploadFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const fileRes = btoa(reader.result);
        setImageSrc(`data:image/jpg;base64,${fileRes}`);
        // console.log(`data:image/jpg;base64,${fileRes}`);
      }
    };
    reader.onerror = () => {
      console.error('There is a problem while uploading...');
    };
  };

  const closeModal = () => {
    setImageSrc('');
    setModal(!modal);
    setLoading(false);
    setIsAvatarUpload(true);

    if (fileInput instanceof HTMLInputElement) {
      fileInput.value = '';
    }
  };

  useEffect(() => {
    if (imageSrc !== '') {
      setIsAvatarUpload(false);
    }
  }, [imageSrc]);

  useEffect(() => {
    if (modal && avatarUrl) {
      setImageSrc(avatarUrl);
    }
  }, [modal, avatarUrl]);

  return (
    <Modal
      width={1072}
      centered={true}
      bodyStyle={{ height: 528 }}
      className={styles._avatarModal}
      title={'Изменить аватар'}
      open={modal}
      onCancel={closeModal}
      footer={[
        <div className={styles._footerButton_Body}>
          {imageSrc &&
            <div className={styles._buttonAnother}>
              <label>
                <Text className={styles.labelButton}> Выбрать другой файл</Text>
                <input
                  className={imageSrc ? styles.cropFalse : styles.inputStyle}
                  type="file"
                  accept=".png,.jpeg,.jpg,.gif"
                  onChange={(e) => handleUpload(e)}
                />
              </label>
            </div>
          }

          <div className={styles.btnDefaults}>
            <Button
              type="default"
              onClick={closeModal}
            >
              {cancel}
            </Button>
            <Button
              loading={loading}
              type="primary"
              onClick={showCroppedImage}
              disabled={isAvatarUpload}
            >
              {save}
            </Button>
          </div>
        </div>,
      ]}
    >
      <label className={styles.avatarLabel}>
        <div
          className={styles.avatarDasher}
        >
          {imageSrc ? (
            <div className={styles._avatarHaveImage}>
              <Cropper
                style={{
                  containerStyle: {
                    marginTop: 105,
                    width: '100%',
                    height: '68.7%',
                  },
                }}
                image={imageSrc}
                crop={crop}
                aspect={1}
                cropShape="round"
                zoom={zoom}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          ) : (
            <div className={styles._borderNotImage}>
              <div className={styles.areaDrag}>
                <input
                  className={imageSrc ? styles.cropFalse : styles.inputStyle}
                  type="file"
                  accept=".png,.jpeg,.jpg,.gif"
                  onChange={(e) => handleUpload(e)}
                  onDrop={(e) => handleUpload(e)}
                />
              </div>
              <div className={styles._textBorder}>
                <Icon className={styles._paperClip} component={LoadAvatarIcon} />
                <Text>
                  Переместите файлы сюда или <span>выберите файлы</span>
                </Text>
              </div>
            </div>
          )}
        </div>
      </label>
    </Modal>
  );
};
