import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, message, RadioChangeEvent, Typography } from 'antd';
import MaskedInput from 'antd-mask-input';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrorIcon } from '../../assets/icons/ErrorIcon';
import { AvatarComponent } from '../../components/AvatarComponent';
import { ContactForm } from '../../components/Forms/ContactForm/ContactForm';
import { MainInfo } from '../../components/Forms/MainInfo/MainInfo';
import { PasswordForm } from '../../components/Forms/PasswordForm/PasswordForm';
import { ResidentialAddress } from '../../components/Forms/ResidentialAddress/ResidentialAddress';
import { SnilsForm } from '../../components/Forms/SnilsForm/SnilsForm';
import { WorkForm } from '../../components/Forms/WorkForm/WorkForm';
import { ChangeEmailModal } from '../../components/Modal/ChangeEmailModal/ChangeEmailModal';
import { ChangePhoneModal } from '../../components/Modal/ChangePhoneModal/ChangePhoneModal';
import { CodeRequestEmail } from '../../components/Modal/CodeRequestEmail/CodeRequestEmail';
import { CodeRequestPhone } from '../../components/Modal/CodeRequestPhone/CodeRequestPhone';
import { ConfirmMilitaryUniversities } from '../../components/Modal/ConfirmMilitaryUniversities/ConfirmMilitaryUniversities';
import { ConfirmPhoneProfile } from '../../components/Modal/ConfirmPhoneProfile/ConfirmPhoneProfile';
import { ConfirmRegistrationData } from '../../components/Modal/ConfirmRegistrationData/ConfirmRegistrationData';
import {
  checkPassword,
  registration,
  getUserGeoData,
  validateSnils,
  confirm_code_email,
  getUserInnData,
} from '../../store/actions/profileAction';
import { useAppDispatch, useAppSelector } from '../../store/store';
import CheckboxStyle from '../../style/CheckBox/CheckboxStyle';
import { InputStyle } from '../../style/Input/InputStyle';
import { ICheckCititzenChange, IJSONData, IOnChangeAddressSelect } from '../../types/interfaces/interfaces';
import { imgConfigurator } from '../../utils/const';
import {
  checkChange,
  dataInJsonFormat,
  getInnValue,
  onFinishFailed,
} from '../../utils/ProfileRegistration';
import styles from './Registration.module.css';

const { Link } = Typography;

export const Registration: FC = () => {
  const [toggleTwo, setToggleTwo] = useState<boolean>(false);
  const [toggleThree, setToggleThree] = useState<boolean>(false);
  const [checkNick, setCheckNick] = useState<boolean>(false);
  const [checkCitizen, setCheckCitizen] = useState<boolean>(false);
  const [editPhoneModal, setEditPhoneModal] = useState<boolean>(false);
  const {
    _is_modal_visible,
    values,
    _token,
    _is_modal_visible_email,
    _url,
    result_valid_inn,
    _is_modal_visible_phone,
    emailConfirm,
    modifiedEmail,
    phoneConfirm,
  } = useAppSelector(({ profileSlice }) => profileSlice);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const check = localStorage.getItem('check');
  const [confirmValue, setConfirmValue] = useState(new Array(4).fill(''));
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [queryState, setQueryState] = useState<null | string>(null);
  const [fiasId, setFiasId] = useState<string | null>('');
  const [fiasAddress, setFiasAddress] = useState<string | null>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [getError, setGetError] = useState<boolean>(false);
  const [isAddressChose, setIsAddressChose] = useState<boolean>(true);
  const [innValue, setInnValue] = useState('');
  const [isOpenInn, setIsOpenInn] = useState<boolean>(false);
  const [openConfirmMilitaryUniversities, setOpenConfirmMilitaryUniversities] = useState<boolean>(false);
  const [militaryUniversities, setMilitaryUniversities] = useState<boolean>(false);
  const [citizenRf, setCitizenRf] = useState<boolean>(false);
  const [isConfirmRegistrationData, setIsConfirmRegistrationData] = useState<boolean>(false);
  const isRegistration: boolean = true;

  const [HOCMasked, setHOCMasked] = useState({
    Input: InputStyle,
    focus: false,
  });

  const regBtn = () => {
    if (toggleTwo && toggleThree) {
      const formErrors = form.getFieldsError().filter(({ errors }) => errors.length).length;
      if (!!formErrors && !getError) {
        setGetError(true);
      }
      return false;
    }
    return true;
  };

  const _setUserData = (): void => {
    try {
      form.setFieldsValue({
        phone: values.phone,
        email: values.email,
      });
    }
    catch (e) {
      console.log('уведомление');
    }
  };

  useLayoutEffect(() => {
    check === 'true' ? setCheckNick(true) : setCheckNick(false);
    _setUserData();
    dispatch(checkPassword(''));
  }, [values]);

  const onFinish = (): void => {
    if (startDate) {
      if (!emailConfirm) {
        message.error({
          className: 'messageError',
          content: 'Вы не подтвердили почту',
          icon: <ErrorIcon />,
        });
        return;
      }
      setIsConfirmRegistrationData(true);
    }
    else {
      getDateValue(null);
      onFinishFailed('');
    }
  };

  const sendRegistrationData = (values: IJSONData) => {
    if (startDate) {
      if (!emailConfirm || !phoneConfirm) {
        message.error({
          className: 'messageError',
          content: `Вы не подтвердили ${emailConfirm ? 'телефон' : 'почту'}`,
          icon: <ErrorIcon />,
        });
        setIsConfirmRegistrationData(false);
        return;
      }
      dispatch(registration(
        {
          data: dataInJsonFormat({ values, _token, fiasAddress, fiasId, startDate }),
          password: values.password,
          isRegistration,
        },
      ));
    }
    setIsConfirmRegistrationData(false);
  };

  const getDateValue = (date: Date | null) => {
    setGetError(true);
    setStartDate(date);
  };

  const onChangeAddressSelect = (value: string, val: IOnChangeAddressSelect) => {
    if (value) {
      setIsAddressChose(true);
      form.setFieldsValue({ address: value });
      setQueryState(value);
      setFiasId(val.fias_id);
      setFiasAddress(value);
      setIsOptionsOpen(false);
    }
  };

  const onSubmitAddressSelect = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (queryState) {
      dispatch(getUserGeoData({ token: _token, query: queryState }));
    }
    setIsOptionsOpen(true);
  };

  const getSnilsValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHOCMasked({ Input: MaskedInput, focus: true });
    dispatch(validateSnils(e.target.value));
  };

  const confirmCode = () => {
    dispatch(confirm_code_email({ token: _token, code: confirmValue.join(''), modifiedEmail, emailConfirm }));
    setConfirmValue(new Array(4).fill(''));
  };

  const onSubmitInnSelect = (e: React.MouseEvent) => {
    e.preventDefault();
    if (innValue && result_valid_inn) {
      dispatch(getUserInnData({ token: _token, query: innValue }));
    }
    setIsOpenInn(true);
  };

  const onChangeInnSelect = (value: any) => {
    if (value) {
      form.setFieldsValue({ inn: innValue });
      form.setFieldsValue({ place: value });
      setIsOpenInn(false);
    }
  };

  const checkCitizenChange = ({ e, setCheckCitizen, form }: ICheckCititzenChange) => {
    setCheckCitizen(e.target.checked);
    if (e.target.checked) {
      form.setFieldsValue({ snils: '' });
    }
    setCitizenRf(false);
    setMilitaryUniversities(false);
  };

  const checkCitizenRf = () => {
    setCitizenRf(true);
    setCheckCitizen(false);
    setMilitaryUniversities(false);
  };

  const clearAddressSelect = () => {
    setFiasAddress(null);
    setQueryState(null);
    setFiasId(null);
  };

  const openConfirmMilitaryUniversitiesModal = (e: RadioChangeEvent) => {
    if (e.target.checked) {
      setOpenConfirmMilitaryUniversities(true);
    }
    else {
      setMilitaryUniversities(false);
    }
  };

  useEffect(() => {
    if (!checkCitizen && !militaryUniversities) {
      setCitizenRf(true);
    }
    else if (checkCitizen) {
      setCitizenRf(false);
      setMilitaryUniversities(false);
    }
    else if (militaryUniversities) {
      setCitizenRf(false);
      setCheckCitizen(false);
    }
  }, [citizenRf, checkCitizen, militaryUniversities]);

  return (
    <div className={styles.registration}>
      <div className={styles.avatarBlock}>
        <AvatarComponent isRegistration={true} />
      </div>

      <div className={styles.body}>
        <div className={styles.registrationBlock}>
          <h4 className={styles.title}>Регистрация</h4>

          <p className={styles.description}>
            Поздравляем! Вы зарегистрированы в системе One ID.
            Чтобы продолжить работу, необходимо заполнить данные.
          </p>
        </div>

        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className={styles.registrationBlock}>
            <Col span={24}>
              <h3 className={styles.smallerTitle}>Личные данные</h3>
            </Col>

            <MainInfo
              setCheckNick={setCheckNick}
              checkNick={checkNick}
              checkChange={checkChange}
              form={form}
              startDate={startDate}
              getError={getError}
              getDateValue={getDateValue}
            />

            <SnilsForm
              checkCitizen={checkCitizen}
              setCheckCitizen={setCheckCitizen}
              getSnilsValue={getSnilsValue}
              checkCitizenChange={checkCitizenChange}
              form={form}
              HOCMasked={HOCMasked}
              militaryUniversities={militaryUniversities}
              openConfirmMilitaryUniversitiesModal={openConfirmMilitaryUniversitiesModal}
              citizenRf={citizenRf}
              checkCitizenRf={checkCitizenRf}
            />

            <ResidentialAddress
              isAddressChose={isAddressChose}
              isOptionsOpen={isOptionsOpen}
              queryState={queryState}
              setIsOptionsOpen={setIsOptionsOpen}
              _token={_token}
              onChangeAddressSelect={onChangeAddressSelect}
              onSubmitAddressSelect={onSubmitAddressSelect}
              clearAddressSelect={clearAddressSelect}
            />
          </div>

          <div className={styles.registrationBlock}>
            <Col span={24}>
              <h3 className={styles.smallerTitle}>Контакты</h3>
              <ContactForm
                _token={_token}
                setEditPhoneModal={setEditPhoneModal}
                form={form}
                isRegistrationPage={true}
              />
            </Col>
          </div>

          <div className={styles.registrationBlock}>
            <Col span={24}>
              <h3 className={styles.smallerTitle}>Место работы</h3>
            </Col>

            <WorkForm
              innValue={innValue}
              isOpenInn={isOpenInn}
              getInnValue={getInnValue}
              setInnValue={setInnValue}
              setIsOpenInn={setIsOpenInn}
              onChangeInnSelect={onChangeInnSelect}
              onSubmitInnSelect={onSubmitInnSelect}
              form={form}
            />
          </div>

          <div className={styles.registrationBlock}>
            <Col span={24}>
              <h3 className={styles.smallerTitle}>Вход в OneID</h3>
            </Col>

            <PasswordForm />

            <div className={styles.line} />

            <Col>
              <Form.Item
                className={styles.formItemCheckBox}
              >
                <CheckboxStyle
                  checked={toggleTwo}
                  isRadio
                  onChange={() => setToggleTwo(!toggleTwo)}
                />
                <span className={styles.text__bottom}>Я даю согласие на</span>
                <Link className={styles.link} target="_blank" href={`${imgConfigurator}web/docs/Consent to the Processing of Personal Data.pdf`}>
                  {' '}
                  обработку персональных данных
                </Link>
              </Form.Item>
              <Form.Item
                className={`${styles.formItemCheckBox} ${styles.formItemCheckBox__margin}`}
              >
                <CheckboxStyle
                  checked={toggleThree}
                  isRadio
                  onChange={() => setToggleThree(!toggleThree)}
                />
                <span className={styles.text__bottom}>Я ознакомлен(а) с</span>
                <Link className={styles.link} target="_blank" href={`${imgConfigurator}web/docs/Policy_processing_personal Data.pdf`}>
                  {' '}
                  политикой обработки персональных данных пользователей
                </Link>
              </Form.Item>
            </Col>

            <Col className={styles.onFinishBtn}>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={regBtn()}
                  >
                    {_url.redirect
                      ? 'Зарегистрироваться и продолжить'
                      : 'Зарегистрироваться'}
                  </Button>
                )}
              </Form.Item>
            </Col>
          </div>
        </Form>
      </div>

      <ChangePhoneModal
        editPhoneModal={editPhoneModal}
        setEditPhoneModal={setEditPhoneModal}
        phone={form.getFieldValue(['phone'])}
        token={_token}
      />

      <ChangeEmailModal
        _token={_token}
      />

      <CodeRequestPhone
        visible={_is_modal_visible}
        setEditPhoneModal={setEditPhoneModal}
        token={_token}
      />

      <CodeRequestEmail
        visible={_is_modal_visible_email}
        confirmCode={confirmCode}
        confirmValue={confirmValue}
        setConfirmValue={setConfirmValue}
        modifiedEmail={modifiedEmail}
      />

      <ConfirmPhoneProfile
        visible={_is_modal_visible_phone}
        token={_token}
      />

      <ConfirmMilitaryUniversities
        openConfirmMilitaryUniversities={openConfirmMilitaryUniversities}
        setOpenConfirmMilitaryUniversities={setOpenConfirmMilitaryUniversities}
        setMilitaryUniversities={setMilitaryUniversities}
        setCitizenRf={setCitizenRf}
        setCheckCitizen={setCheckCitizen}
      />

      <ConfirmRegistrationData
        form={form}
        isConfirmRegistrationData={isConfirmRegistrationData}
        setIsConfirmRegistrationData={setIsConfirmRegistrationData}
        sendRegistrationData={sendRegistrationData}
      />
    </div>
  );
};
