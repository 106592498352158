import React from 'react';
import { Row, Spin } from 'antd';

const IsLoadingComponent = () => (
  <Row style={{ height: '80vh' }} justify={'center'} align={'middle'}>
    <Spin size={'large'} />
    <h1 style={{ color: '#5b97e1', marginLeft: 30 }}>Идет загрузка страницы...</h1>
  </Row>
);

export default IsLoadingComponent;
