import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import cn from 'classnames';
import { Drots } from '../../../assets/icons/Drots';
import { requestSMS } from '../../../store/actions/profileAction';
import { changeAcronym, ModifiedPhone } from '../../../store/profileSlice';
import { useAppDispatch } from '../../../store/store';
import { AcronymEnum } from '../../../types/entities/entitites';
import { IChangePhoneModal } from '../../../types/interfaces/interfaces';
import { phoneValid } from '../../../utils';
import { cancel, getMask, selectOptions } from '../../../utils/const';
import styles from './ChangePhoneModal.module.css';

export const ChangePhoneModal: FC<IChangePhoneModal> = ({ editPhoneModal, setEditPhoneModal, phone, token }) => {
  const [phoneValue, setPhoneValue] = useState('');
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const error = !!form.getFieldsError().filter(({ errors }) => errors.length).length;
  const [countryCode, setCountryCode] = useState('+7');
  const [mask, setMask] = useState('(000)000-00-00');
  const [acronym, setAcronym] = useState<AcronymEnum>(AcronymEnum.rf);

  const btnDisabled = () => {
    const value = countryCode.replace(/[^0-9]/g, '');
    const newPhone = phoneValue?.replace(/[^+\d]/g, '');
    if (value === '996' || value === '375' || value === '998') {
      return !(newPhone.length === 9);
    }
    if (value === '8' || value === '374' || value === '976') {
      return !(newPhone.length === 10);
    }
    if (value === '86') {
      return !(newPhone.length === 11);
    }
    return true;
  };

  const _setUserData = () => {
    try {
      form.setFieldsValue({
        phoneValue: phone,
      });
      setPhoneValue(phone);
    }
    catch (e) {
      console.log('уведомление');
    }
  };

  const handleChange = (value: string) => {
    const countryValue = JSON.parse(value);
    const currentCountryCode = countryValue.code.replace(/[^0-9]/g, '');
    setAcronym(countryValue.acronym);
    setCountryCode(currentCountryCode);
    setPhoneValue('0');
    setMask(getMask(currentCountryCode));
  };

  useLayoutEffect(() => {
    _setUserData();
  }, [phone]);

  useEffect(() => {
    setPhoneValue('');
  }, [acronym]);

  useEffect(() => {
    if (countryCode === '7' || countryCode === '+7') {
      setCountryCode('8');
    }
  }, [countryCode]);

  useEffect(() => {
    setPhoneValue('');
    form.resetFields(['phoneValue']);
    form.setFieldsValue({ phoneValue: phone });
  }, [editPhoneModal]);

  const handleChangeOpenModal = () => {
    const changedPhone = countryCode + phoneValue.replace(/[^+\d]/g, '');
    dispatch(ModifiedPhone(changedPhone));
    dispatch(changeAcronym(acronym));
    dispatch(requestSMS({ phone: changedPhone, token, modal: true }));
    setEditPhoneModal(false);
  };

  const handleChangePhoneCancel = () => {
    setAcronym(AcronymEnum.rf);
    if (!error) {
      setEditPhoneModal(false);
    }
  };

  const onChangeHandler = (e: any) => {
    setPhoneValue(e.target.value);
  };

  return (
    <div>
      <Modal
        forceRender
        className={styles.changeNumModal}
        open={editPhoneModal}
        onCancel={handleChangePhoneCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={handleChangeOpenModal}
            className={styles._modalBtnSave}
            disabled={btnDisabled()}
          >
            Получить код
          </Button>,
          <Button
            type="link"
            key="back"
            onClick={handleChangePhoneCancel}
            className={styles._modalBtnCancel}
          >
            {cancel}
          </Button>,
        ]}
      >
        <div className={styles._phoneModalBody}>
          <h2 className={styles._modalTitle}>Изменить номер телефона</h2>
          <p className={styles._modalText}>
            Введите новый номер телефона, на этот номер будет отправлен код подтверждения
          </p>
          <Form
            form={form}
            layout="vertical"
          >
            <Form.Item
              name="phoneValue"
              className={cn(styles.inputWidth, { [styles.errorInput]: error })}
              rules={[{ required: true, min: 4 },
                {
                  validator: (_, value) => (value &&
                  phoneValid.test(value)
                    ? Promise.resolve('okay')
                    : Promise.reject('Некорректный номер телефона')),
                },
              ]}
              hasFeedback
            >
              <Input.Group className={styles.inpGroup}>
                <Select
                  defaultValue='{"code": "+7", "acronym": "RF"}'
                  onChange={handleChange}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                  {selectOptions}
                </Select>
                <span className={styles.line} />
                <MaskedInput
                  className={styles.maskedInput}
                  mask={mask}
                  name="phoneValue"
                  size="middle"
                  allowClear
                  type="text"
                  placeholder={mask}
                  value={phoneValue}
                  onChange={onChangeHandler}
                />
                <Icon component={Drots} className={styles.drots} />
              </Input.Group>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
