import React, { FC, useState } from 'react';
import { Button, Modal } from 'antd';
import { telegramBind } from '../../../store/actions/telegramActions';
import { BindTelegramModalVisible } from '../../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { InputStyle } from '../../../style/Input/InputStyle';
import styles from './BindTelegramModal.module.css';

export const BindTelegramModal:FC = () => {
  const { _token, _is_bind_telegram_modal_visible } = useAppSelector(({ profileSlice }) => profileSlice);
  const linkToTelegramBot: string | undefined = process.env.REACT_APP_LINK_TO_TELEGRAM_BOT;
  const [values, setValues] = useState<string>('');
  const dispatch = useAppDispatch();

  const confirmCode = () => {
    const data = {
      telegram_id: values,
      token: _token,
    };
    dispatch(telegramBind(data));
  };

  const onHandlerCancel = () => {
    dispatch(BindTelegramModalVisible(false));
  };

  return (
    <Modal
      className={styles.modal}
      open={_is_bind_telegram_modal_visible}
      onCancel={onHandlerCancel}
      footer={[
        <Button
          type="primary"
          htmlType="submit"
          className={styles.modalBtnBind}
          disabled={!values.length}
          onClick={confirmCode}
        >
          Привязать
        </Button>,
      ]}
    >
      <h2 className={styles.title}>
        Привязать Telegram
      </h2>
      <p className={styles.text}>
        Перейдите по ссылке чтобы получить код из телеграмм бота.
      </p>
      <a
        href={linkToTelegramBot}
        className={styles.link}
        target="_blank"
      >
        {linkToTelegramBot}
      </a>

      <InputStyle
        type="text"
        placeholder="Введите код"
        className={styles.input}
        value={values}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValues(e.target.value)}
      />
    </Modal>
  );
};
