import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import {
  checkPassword,
  update_password,
} from '../../../../store/actions/profileAction';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { IChangePassword } from '../../../../types/interfaces/interfaces';
import { base_image_url, cancel, imgConfigurator } from '../../../../utils/const';
import { Avatar } from '../../../Avatar/Avatar';
import { ChangePasswordForm } from '../../../Forms/ChangePasswordForm/ChangePasswordForm';
import styles from './PasswordEdit.module.css';


export const PasswordEdit: FC<{ setIsProfileView: (s: string) => void }> = ({ setIsProfileView }) => {
  const { values, _token, s3_token } = useAppSelector(({ profileSlice }) => profileSlice);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const userFullName = `${values.user_info.passport.sur_name} ${values.user_info.passport.name} ${values.user_info.passport.patronymic}`;
  const [avatarUrl, setAvatarUrl] = useState('');
  const isVerify = values.moderation.is_moderated;

  useLayoutEffect(() => {
    dispatch(checkPassword(''));
  }, [values]);

  const onFinish = (values: IChangePassword) => {
    dispatch(update_password({ token: _token, password: values.password }));
  };

  useEffect(() => {
    if (values.avatar) {
      setAvatarUrl(`${imgConfigurator}${values.avatar}?s3token=${s3_token}`);
    }
  }, [values.avatar]);

  const avatarUrlFunc = (
    currentTarget: EventTarget & HTMLImageElement,
  ) => {
    if (currentTarget.src !== `${base_image_url}/${values.avatar}?s3token=${s3_token}`) {
      setAvatarUrl(`${base_image_url}/${values.avatar}?s3token=${s3_token}`);
    }
  };

  return (
    <div className={styles.passwordEdit}>
      <Row align="middle" className={styles.userInfo} gutter={[0, 24]}>
        <Col className={styles.avatar}>
          <Avatar
            avatarUrl={avatarUrl}
            isVerify={isVerify}
            onError={({ currentTarget }) => avatarUrlFunc(currentTarget)}
          />
        </Col>
        <Col className={styles.mainInfo}>
          <p className={styles.userId}>ID: {values.id}</p>
          <h2 className={styles.title}>
            {userFullName}
          </h2>
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row className={styles.userInfo}>
          <Col span={24}>
            <h5 className={styles.formTitle}>Смена пароля</h5>
          </Col>
          <Col span={24}>
            <ChangePasswordForm />
          </Col>
        </Row>
        <Row gutter={[12, 12]} className={styles.btnActions} justify="end">
          <Col>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                className={styles.btnCancel}
                onClick={() => setIsProfileView('profileView')}
              >
                {cancel}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              shouldUpdate
            >
              {({ getFieldsValue, getFieldsError }) => {
                const { password, confirmPassword } = getFieldsValue();
                const errors = getFieldsError().find((errorObj) => errorObj.errors && errorObj.errors.length);
                const formIsComplete = !!password && !!confirmPassword && !errors;
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!formIsComplete}
                  >
                    Сохранить изменения
                  </Button>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
