import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorsInn, ResultValidateInn } from '../store/profileSlice';

// Валидация ИНН
export const validateInn = createAsyncThunk(
  'profileSlice/validateInn',
  async(inn: string, { dispatch }) => {
    let result = false;
    if (typeof inn !== 'string') {
      inn = '';
    }
    if (!inn.length) {
      dispatch(ErrorsInn('ИНН пуст'));
    }
    else if (/[^0-9]/.test(inn)) {
      dispatch(ErrorsInn('ИНН может состоять только из цифр'));
    }
    else if ([10, 12].indexOf(inn.length) === -1) {
      dispatch(ErrorsInn('ИНН может состоять только из 10 или 12 цифр'));
    }
    else {
      const checkDigit = function({ inn, coefficients }:{ inn:any, coefficients:number[] }) {
        let n = 0;
        for (const i in coefficients) {
          n += coefficients[i] * inn[i];
        }
        return parseInt(String(n % 11 % 10));
      };
      // eslint-disable-next-line default-case
      switch (inn.length) {
        case 10:
          const n10 = checkDigit({ inn, coefficients: [2, 4, 10, 3, 5, 9, 4, 6, 8] });
          if (n10 === parseInt(inn[9])) {
            result = true;
          }
          break;
        case 12:
          const n11 = checkDigit({ inn, coefficients: [7, 2, 4, 10, 3, 5, 9, 4, 6, 8] });
          const n12 = checkDigit({ inn, coefficients: [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8] });
          if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
            result = true;
          }
          break;
      }
      if (!result) {
        dispatch(ErrorsInn('Неправильное контрольное число'));
      }
    }
    dispatch(ResultValidateInn(result));
  },
);
