import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import Text from 'antd/es/typography/Text';
import page from '../../assets/image/page-not-found.png';

export const NotFound: FC = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Row justify={'center'} align={'middle'} style={{ height: '80vh' }}>
      <Col span={20} push={5}>
        <Button style={{ border: '2px solid ' }} type="link" onClick={goBack}>
                    назад
        </Button>
      </Col>

      <Col>
        <Text strong style={{ fontSize: 20 }}>
                    Ops... not found page!
        </Text>
        <img style={{ width: 300, height: 300 }} src={page} alt="" />
      </Col>
    </Row>
  );
};
