import React, { FC } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { getUserGeoData } from '../../../store/actions/profileAction';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { IAddressForm } from '../../../types/interfaces/interfaces';
import { debounce } from '../../../utils/debounce';
import { clickOutsideClose, handleKeyDown } from '../../../utils/ProfileRegistration';
import styles from './ResidentialAddress.module.css';

export const ResidentialAddress:FC<IAddressForm> = ({
  isAddressChose,
  isOptionsOpen,
  queryState,
  setIsOptionsOpen,
  _token,
  onChangeAddressSelect,
  onSubmitAddressSelect,
  clearAddressSelect,
}) => {
  const { address } = useAppSelector(({ profileSlice }) => profileSlice);
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const addressSearch = debounce((e: string) => {
    dispatch(getUserGeoData({ token: _token, query: e }));
    setIsOptionsOpen(true);
  }, 1000);

  const handleOnKeyDown: React.KeyboardEventHandler<HTMLDivElement> | undefined = (e) => {
    handleKeyDown({ e, onSubmitAddressSelect });
  };

  const handleOnBlur: React.FocusEventHandler<HTMLElement> | undefined = (e) => {
    clickOutsideClose({ e, setIsOptionsOpen });
  };

  return (
    <div className={styles.address}>
      <Row>
        <Col span={24}>
          <Form.Item
            hasFeedback
            className={styles.addressForm}
            label="Адрес"
            rules={[
              {
                type: 'string',
                required: false,
                message: 'Выберите адрес',
              },
              {
                validator() {
                  if (isAddressChose && !isOptionsOpen) {
                    return Promise.resolve(true);
                  }
                  if (!queryState) {
                    return Promise.resolve(true);
                  }
                  return Promise.reject('Выберите адрес из списка');
                },
              },
            ]}
            name="address"
          >
            <Select
              showSearch
              allowClear
              className={styles.addressSelect}
              value={queryState}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              placeholder="Введите данные"
              onSearch={(e) => addressSearch(e)}
              onChange={onChangeAddressSelect}
              open={isOptionsOpen}
              onKeyDown={handleOnKeyDown}
              onBlur={handleOnBlur}
              onClear={clearAddressSelect}
              notFoundContent="Необходимо ввести адрес"
            >
              {address.length &&
                address.map((item) => (
                  <Option
                    key={item.data.fias_id}
                    value={item.value}
                    fias_id={item.data.fias_id}
                  >
                    {item.value}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
