import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Col, Row, message } from 'antd';
import 'moment/locale/ru';
import { EmailIcon } from '../../../../assets/icons/EmailIcon';
import { ErrorIcon } from '../../../../assets/icons/ErrorIcon';
import { HasConfirmIcon } from '../../../../assets/icons/HasConfirmIcon';
import { PhoneIcon } from '../../../../assets/icons/PhoneIcon';
import { WarningIcon } from '../../../../assets/icons/WarningIcon';
import { useAppSelector } from '../../../../store/store';
import { errorsKey } from '../../../../utils';
import { base_image_url, imgConfigurator } from '../../../../utils/const';
import { phoneMask } from '../../../../utils/PhoneFilter';
import { Avatar } from '../../../Avatar/Avatar';
import { LoginToOneId } from '../../../Forms/LoginToOneID/LoginToOneID';
import styles from './ProfileView.module.css';


const ProfileView: FC<{ setIsProfileView: (arg: string) => void }> = ({ setIsProfileView }) => {
  const { values, need_fields, s3_token } = useAppSelector(({ profileSlice }) => profileSlice);
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const userBirthday = startDate?.toLocaleString('ru', options);
  const userFullName = `${values.user_info.passport.sur_name} ${values.user_info.passport.name} ${values.user_info.passport.patronymic}`;
  const userAddress = values.user_info.geo.fias_addr;
  const userPhone = phoneMask(values.phone);
  const userEmail = values.email;
  const userSnils = values.user_info.docs.snils;
  const userWorkplace = values.user_info.work.place;
  const userPositions = values.user_info.work.position;
  const emailConfirm = values.confirm.email;
  const phoneConfirm = values.confirm.phone;
  const idDocName = values.user_info.docs.id_doc_name;
  const idDoc = values.user_info.docs.id_doc;
  const inn = values.user_info.work.inn;
  const isVerify = values.moderation.is_moderated;
  const ogrn = values.user_info.work.ogrn;

  let userSex = values.sex;
  if (userSex === 'female') {
    userSex = 'Женский';
  }
  else if (userSex === 'male') {
    userSex = 'Мужской';
  }

  useLayoutEffect(() => {
    if (values.user_info?.birthday?.year && values.user_info?.birthday?.month && values.user_info?.birthday?.day) {
      setStartDate(new Date(values.user_info?.birthday?.year, values.user_info?.birthday?.month - 1, values.user_info?.birthday?.day));
    }
  }, []);

  useEffect(() => {
    if (values.avatar) {
      setAvatarUrl(`${imgConfigurator}${values.avatar}?s3token=${s3_token}`);
    }
  }, [values.avatar]);

  const avatarUrlFunc = (
    currentTarget: EventTarget & HTMLImageElement,
  ) => {
    if (currentTarget.src !== `${base_image_url}/${values.avatar}`) {
      setAvatarUrl(`${base_image_url}/${values.avatar}?s3token=${s3_token}`);
    }
  };

  useEffect(() => {
    if (!need_fields) {
      if (!emailConfirm) {
        const show = errorsKey(7778);
        message.error({
          className: 'messageError',
          content: show,
          icon: <ErrorIcon />,
        });
      }
      if (!phoneConfirm) {
        const show = errorsKey(7779);
        message.error({
          className: 'messageError',
          content: show,
          icon: <ErrorIcon />,
        });
      }
      const show = errorsKey(7777);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  }, []);

  const phoneConfirmText = useMemo(() => {
    const russianPhone = values.phone.startsWith('89') && values.phone.length === 11;
    if (!russianPhone) {
      return 'Подтвержден';
    }
    return phoneConfirm ? 'Подтвержден' : 'Не подтвержден';
  }, [values.phone, phoneConfirm]);

  return (
    <div className={styles.profileView}>
      <Row align="middle" className={styles.userInfo}>
        <Col className={styles.avatar}>
          <Avatar
            avatarUrl={avatarUrl}
            isVerify={isVerify}
            onError={({ currentTarget }) => avatarUrlFunc(currentTarget)}
          />
        </Col>
        <Col className={styles.mainInfo}>
          <p className={styles.userId}>ID: {values.id}</p>
          <h2 className={styles.title}>
            {userFullName}
          </h2>
        </Col>
        <Col className={styles.changeData}>
          <Button
            type="default"
            onClick={() => setIsProfileView('profileEdit')}
          >
            Редактировать
          </Button>
        </Col>
      </Row>

      <Row justify="start" className={styles.userInfo}>
        <Col span={24}>
          <h3 className={styles.userInfoTitle}>Личные данные</h3>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.userInfoBlockMain}>
            <p className={styles.userInfoLabel}>Дата рождения</p>
            <p className={styles.userInfoValue}>{userBirthday}</p>
          </div>
        </Col>
        {idDocName && idDoc ? (
          <Col xs={24} sm={12}>
            <div className={styles.userInfoBlockMain}>
              <p className={styles.userInfoLabel}>{idDocName}</p>
              <p className={styles.userInfoValue}>{idDoc}</p>
            </div>
          </Col>
        ) : (
          <Col xs={24} sm={12}>
            <div className={styles.userInfoBlockMain}>
              <p className={styles.userInfoLabel}>СНИЛС</p>
              <p className={styles.userInfoValue}>{userSnils}</p>
            </div>
          </Col>
        )}
        <Col xs={24} sm={12}>
          <div className={styles.userInfoBlockMain}>
            <p className={styles.userInfoLabel}>Пол</p>
            <p className={styles.userInfoValue}>{userSex}</p>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.userInfoBlockMain}>
            <p className={styles.userInfoLabel}>Адрес</p>
            <p className={styles.userInfoValue}>{userAddress}</p>
          </div>
        </Col>
      </Row>

      <Row className={styles.userInfo}>
        <Col span={24}>
          <h3 className={styles.userInfoTitle}>Контакты</h3>
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]} wrap={true}>
            <Col xs={24} sm={24} lg={12}>
              <div className={styles.userInfoBlock}>
                <Icon
                  component={PhoneIcon}
                  className={styles.userInfoIcon}
                />
                <div className={styles.userInfoBlockMain}>
                  <p className={styles.userInfoValue}>{userPhone}</p>
                  <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>{phoneConfirmText}</p>
                </div>
                {phoneConfirmText === 'Подтвержден'
                  ? (<Icon component={HasConfirmIcon} />)
                  : (
                    <Icon
                      component={WarningIcon}
                      className={styles.warningIcon}
                    />
                  )
                }
              </div>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <div className={styles.userInfoBlock}>
                <Icon
                  component={EmailIcon}
                  className={styles.userInfoIcon}
                />
                <div className={styles.userInfoBlockMain}>
                  <p className={styles.userInfoValue}>{userEmail}</p>
                  <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>Подтвержден</p>
                </div>
                {emailConfirm
                  ? (<Icon component={HasConfirmIcon} />)
                  : (
                    <Icon
                      component={WarningIcon}
                      className={styles.warningIcon}
                    />
                  )
                }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={styles.userInfo} gutter={[16, 24]}>
        <Col span={24}>
          <h3 className={styles.userInfoTitle}>Место работы</h3>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.userInfoBlockMain}>
            <p className={styles.userInfoLabel}>Организация</p>
            <p className={styles.userInfoValue}>{userWorkplace}</p>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.userInfoBlockMain}>
            <p className={styles.userInfoLabel}>ИНН организации</p>
            <p className={styles.userInfoValue}>{inn}</p>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.userInfoBlockMain}>
            <p className={styles.userInfoLabel}>Должность</p>
            <p className={styles.userInfoValue}>{userPositions}</p>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className={styles.userInfoBlockMain}>
            <p className={styles.userInfoLabel}>ОГРН/ОГРНИП</p>
            <p className={styles.userInfoValue}>{ogrn}</p>
          </div>
        </Col>
      </Row>

      <LoginToOneId
        setIsProfileView={setIsProfileView}
      />
    </div>
  );
};

export default ProfileView;
