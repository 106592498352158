import React, { FC } from 'react';
import { Modal, Row } from 'antd';
import { IProctoringViewProps } from '../../../types/interfaces/interfaces';
import { proctoringUrl } from '../../../utils/ProfileRegistration';
import styles from './ProctoringView.module.css';

export const ProctoringView: FC<IProctoringViewProps> = ({ img, modal, setModal, proctoringImageItem, s3_token }) => (
  <Modal
    title={[
      <Row>
        <h2>Фото для прокторинга</h2>
      </Row>,
    ]}
    footer={null}
    open={modal}
    onCancel={() => setModal(false)}
    width={1100}
    centered={true}
  >
    <Row justify={'center'} align={'middle'}>
      <img
        className={styles.proctoringImage}
        src={img}
        alt=""
        onError={({ currentTarget }) => {
          if (proctoringImageItem) {
            proctoringUrl(currentTarget, proctoringImageItem, s3_token);
          }
        }}
      />
    </Row>
  </Modal>

);
