import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, message, RadioChangeEvent, Row } from 'antd';
import MaskedInput from 'antd-mask-input';
import { ErrorIcon } from '../../../../assets/icons/ErrorIcon';
import {
  confirm_code_email,
  getUserGeoData,
  getUserInnData,
  set_users,
  validateSnils,
} from '../../../../store/actions/profileAction';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { InputStyle } from '../../../../style/Input/InputStyle';
import { ICheckCititzenChange, IJSONData, IOnChangeAddressSelect } from '../../../../types/interfaces/interfaces';
import { cancel } from '../../../../utils/const';
import {
  checkChange,
  dataInJsonFormat,
  getInnValue,
  onFinishFailed,
} from '../../../../utils/ProfileRegistration';
import { AvatarComponent } from '../../../AvatarComponent';
import { ContactForm } from '../../../Forms/ContactForm/ContactForm';
import { LoginToOneId } from '../../../Forms/LoginToOneID/LoginToOneID';
import { MainInfo } from '../../../Forms/MainInfo/MainInfo';
import { ResidentialAddress } from '../../../Forms/ResidentialAddress/ResidentialAddress';
import { SnilsForm } from '../../../Forms/SnilsForm/SnilsForm';
import { WorkForm } from '../../../Forms/WorkForm/WorkForm';
import { ChangeEmailModal } from '../../../Modal/ChangeEmailModal/ChangeEmailModal';
import { ChangePhoneModal } from '../../../Modal/ChangePhoneModal/ChangePhoneModal';
import { CodeRequestEmail } from '../../../Modal/CodeRequestEmail/CodeRequestEmail';
import { CodeRequestPhone } from '../../../Modal/CodeRequestPhone/CodeRequestPhone';
import { ConfirmMilitaryUniversities } from '../../../Modal/ConfirmMilitaryUniversities/ConfirmMilitaryUniversities';
import { ConfirmPhoneProfile } from '../../../Modal/ConfirmPhoneProfile/ConfirmPhoneProfile';
import styles from './ProfileEdit.module.css';


export const ProfileEdit: FC<{ setIsProfileView: (s: string) => void }> = ({ setIsProfileView }) => {
  const {
    _token,
    _is_modal_visible,
    values,
    _is_modal_visible_email,
    result_valid_inn,
    _is_modal_visible_phone,
    modifiedEmail,
    emailConfirm,
    phoneConfirm,
  } = useAppSelector(({ profileSlice }) => profileSlice);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  let date: Date;
  const check = localStorage.getItem('check');
  const [checkNick, setCheckNick] = useState(false);
  const [checkCitizen, setCheckCitizen] = useState(false);
  const [editPhoneModal, setEditPhoneModal] = useState(false);
  const [confirmValue, setConfirmValue] = useState(new Array(4).fill(''));
  const [HOCMasked, setHOCMasked] = useState({
    Input: InputStyle,
    focus: false,
  });
  const [queryState, setQueryState] = useState<string | null>(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [fiasId, setFiasId] = useState<string | null>(values.user_info.geo.fias_id);
  const [fiasAddress, setFiasAddress] = useState<string | null>(
    values.user_info.geo.fias_addr,
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [isAddressChose, setIsAddressChose] = useState(true);
  const [innValue, setInnValue] = useState('');
  const [isOpenInn, setIsOpenInn] = useState(false);
  const userFullName = `${values.user_info.passport.sur_name} ${values.user_info.passport.name} ${values.user_info.passport.patronymic}`;
  const getError = true;
  const [openConfirmMilitaryUniversities, setOpenConfirmMilitaryUniversities] = useState(false);
  const [militaryUniversities, setMilitaryUniversities] = useState(false);
  const [citizenRf, setCitizenRf] = useState(false);

  const images = {
    diploma: values.user_info.docs.images.diploma,
    passport: values.user_info.docs.images.passport,
    proctoring: values.user_info.docs.images.proctoring,
    qualification: values.user_info.docs.images.qualification,
  };

  const setUserData = () => {
    try {
      form.setFieldsValue({
        sur_name: values.user_info.passport.sur_name,
        name: values.user_info.passport.name,
        sex: values.sex,
        patronymic: values.user_info.passport.patronymic,
        birthday: date,
        place: values.user_info.work.place,
        position: values.user_info.work.position,
        industry: values.user_info.work.industry,
        phone: values.phone,
        email: values.email,
        address: values.user_info.geo.fias_addr,
        snils: values.user_info.docs.snils,
        snils_dop: values.user_info.docs.snils_dop,
        idDoc: values.user_info.docs.id_doc,
        idDocName: values.user_info.docs.id_doc_name,
        inn: values.user_info.work.inn,
      });
    }
    catch (e) {
      console.log('уведомление');
    }
  };


  useLayoutEffect(() => {
    check === 'true' ? setCheckNick(true) : setCheckNick(false);
    if (!values.user_info.passport.patronymic) {
      setCheckNick(true);
    }
    setUserData();
    setQueryState(form.getFieldValue(['address']));
    setInnValue(form.getFieldValue(['inn']));
    if (form.getFieldValue(['idDocName'])) {
      setCheckCitizen(true);
    }
    if (form.getFieldValue(['snils_dop'])) {
      setMilitaryUniversities(true);
    }
    if (form.getFieldValue(['snlis'])) {
      setCitizenRf(true);
    }
    if (values.user_info?.birthday?.year && values.user_info?.birthday?.month && values.user_info?.birthday?.day) {
      date = new Date(values.user_info?.birthday?.year, values.user_info?.birthday?.month - 1, values.user_info?.birthday?.day);
      setStartDate(date);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      phone: values.phone,
      email: values.email,
    });
  }, [values]);

  const onFinish = (values: IJSONData) => {
    if (startDate) {
      const russianPhone = values.phone.startsWith('89') && values.phone.length === 11;
      if (!phoneConfirm && russianPhone) {
        message.error({
          className: 'messageError',
          content: 'Подтвердите номер телефона',
          icon: <ErrorIcon />,
        });
        return;
      }
      dispatch(
        set_users(
          dataInJsonFormat({ values, _token, fiasAddress, fiasId, startDate, images }),
        ),
      );
    }
    else {
      onFinishFailed('');
    }
  };

  const checkCitizenChange = ({ e, setCheckCitizen }: ICheckCititzenChange) => {
    setCheckCitizen(e.target.checked);
    setCitizenRf(false);
    setMilitaryUniversities(false);
  };

  const onChangeAddressSelect = (value: string, val: IOnChangeAddressSelect) => {
    if (value) {
      setIsAddressChose(true);
      form.setFieldsValue({ address: value });
      setQueryState(value);
      setFiasId(val.fias_id);
      setFiasAddress(value);
      setIsOptionsOpen(false);
    }
  };

  const onSubmitAddressSelect = (e: any) => {
    e.preventDefault();
    if (queryState) {
      dispatch(getUserGeoData({ token: _token, query: queryState }));
    }
    setIsOptionsOpen(true);
  };

  const getSnilsValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHOCMasked({ Input: MaskedInput, focus: true });
    dispatch(validateSnils(e.target.value));
  };

  const confirmCode = () => {
    dispatch(confirm_code_email({ token: _token, code: confirmValue.join(''), modifiedEmail, emailConfirm }));
    setConfirmValue(new Array(4).fill(''));
  };

  const onSubmitInnSelect = (e: any) => {
    e.preventDefault();
    if (innValue && result_valid_inn) {
      dispatch(getUserInnData({ token: _token, query: innValue }));
    }
    setIsOpenInn(true);
  };

  const onChangeInnSelect = (value: any) => {
    if (value) {
      form.setFieldsValue({ inn: innValue });
      form.setFieldsValue({ place: value });
      setIsOpenInn(false);
    }
  };

  const clearAddressSelect = () => {
    setFiasAddress(null);
    setQueryState(null);
    setFiasId(null);
  };

  const openConfirmMilitaryUniversitiesModal = (e: RadioChangeEvent) => {
    if (e.target.checked) {
      setOpenConfirmMilitaryUniversities(true);
    }
    else {
      setMilitaryUniversities(false);
    }
  };

  const checkCitizenRf = () => {
    setCitizenRf(true);
    setCheckCitizen(false);
    setMilitaryUniversities(false);
  };

  const getDateValue = (date: Date) => {
    setStartDate(date);
  };

  useEffect(() => {
    if (!checkCitizen && !militaryUniversities) {
      setCitizenRf(true);
    }
    else if (checkCitizen) {
      setCitizenRf(false);
      setMilitaryUniversities(false);
    }
    else if (militaryUniversities) {
      setCitizenRf(false);
      setCheckCitizen(false);
    }
  }, [citizenRf, checkCitizen, militaryUniversities]);

  return (
    <div className={styles.profileEdit}>
      <Row align="middle" className={styles.userInfo}>
        <AvatarComponent />
        <div className={styles.mainInfo}>
          <p className={styles.description}>
            ID: {values.id}
          </p>
          <h2 className={styles.title}>{userFullName}</h2>
        </div>
      </Row>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className={styles.userInfoBlock}>
          <Col span={24}>
            <h3 className={styles.userInfoTitle}>Личные данные</h3>
          </Col>
          <MainInfo
            setCheckNick={setCheckNick}
            checkNick={checkNick}
            checkChange={checkChange}
            form={form}
            startDate={startDate}
            getError={getError}
            getDateValue={getDateValue}
          />

          <SnilsForm
            checkCitizen={checkCitizen}
            setCheckCitizen={setCheckCitizen}
            getSnilsValue={getSnilsValue}
            checkCitizenChange={checkCitizenChange}
            form={form}
            HOCMasked={HOCMasked}
            militaryUniversities={militaryUniversities}
            openConfirmMilitaryUniversitiesModal={openConfirmMilitaryUniversitiesModal}
            citizenRf={citizenRf}
            checkCitizenRf={checkCitizenRf}
          />

          <ResidentialAddress
            isAddressChose={isAddressChose}
            isOptionsOpen={isOptionsOpen}
            queryState={queryState}
            setIsOptionsOpen={setIsOptionsOpen}
            _token={_token}
            onChangeAddressSelect={onChangeAddressSelect}
            onSubmitAddressSelect={onSubmitAddressSelect}
            clearAddressSelect={clearAddressSelect}
          />
        </div>

        <div className={styles.userInfoBlock}>
          <Col span={24}>
            <h3 className={styles.userInfoTitle}>Контакты</h3>
          </Col>
          <ContactForm
            _token={_token}
            setEditPhoneModal={setEditPhoneModal}
            form={form}
          />
        </div>

        <div className={styles.userInfoBlock}>
          <Col span={24}>
            <h3 className={styles.userInfoTitle}>Место работы</h3>
          </Col>
          <WorkForm
            innValue={innValue}
            isOpenInn={isOpenInn}
            getInnValue={getInnValue}
            setInnValue={setInnValue}
            setIsOpenInn={setIsOpenInn}
            onChangeInnSelect={onChangeInnSelect}
            onSubmitInnSelect={onSubmitInnSelect}
            form={form}
          />
        </div>

        <LoginToOneId
          setIsProfileView={setIsProfileView}
        />

        <div className={styles.actions__btn}>
          <Form.Item>
            <Button
              type="default"
              htmlType="submit"
              className={styles.actions__btn__right}
              onClick={() => setIsProfileView('profileView')}
            >
              {cancel}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
               Сохранить изменения
            </Button>
          </Form.Item>
        </div>
      </Form>

      <ChangePhoneModal
        editPhoneModal={editPhoneModal}
        setEditPhoneModal={setEditPhoneModal}
        phone={form.getFieldValue(['phone'])}
        token={_token}
      />

      <ChangeEmailModal
        _token={_token}
      />

      <CodeRequestPhone
        visible={_is_modal_visible}
        setEditPhoneModal={setEditPhoneModal}
        token={_token}
      />

      <CodeRequestEmail
        visible={_is_modal_visible_email}
        confirmCode={confirmCode}
        confirmValue={confirmValue}
        setConfirmValue={setConfirmValue}
        modifiedEmail={modifiedEmail}
      />

      <ConfirmPhoneProfile
        visible={_is_modal_visible_phone}
        token={_token}
      />

      <ConfirmMilitaryUniversities
        openConfirmMilitaryUniversities={openConfirmMilitaryUniversities}
        setOpenConfirmMilitaryUniversities={setOpenConfirmMilitaryUniversities}
        setMilitaryUniversities={setMilitaryUniversities}
        setCitizenRf={setCitizenRf}
        setCheckCitizen={setCheckCitizen}
      />
    </div>
  );
};
