import React, { FC } from 'react';
import { Button, Col, Form, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { InputStyle } from '../../../style/Input/InputStyle';
import { IWorkForm } from '../../../types/interfaces/interfaces';
import {
  clickOutsideInnClose,
  handleKeyDownInn,
} from '../../../utils/ProfileRegistration';
import styles from './WorkForm.module.css';

export const WorkForm: FC<IWorkForm> = ({
  innValue,
  isOpenInn,
  getInnValue,
  setInnValue,
  setIsOpenInn,
  onChangeInnSelect,
  onSubmitInnSelect,
  form,
}) => {
  const { inn, errors_inn, result_valid_inn } = useAppSelector(({ profileSlice }) => profileSlice);
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const clearWorkForm = () => {
    setInnValue('');
    form.setFieldsValue({ place: '' });
    form.setFieldsValue({ inn: '' });
  };

  return (
    <div className={styles.workForm}>
      <Row gutter={32}>
        <Col sm={24} md={12}>
          <Form.Item
            hasFeedback={!!(errors_inn)}
            validateStatus={errors_inn && !result_valid_inn ? 'error' : 'success'}
            className={`${styles.addressForm} ${errors_inn && !result_valid_inn && styles.errorInnBlock}`}
            label="ИНН организации"
            name="inn"
          >
            <Select
              showSearch
              allowClear
              className={`${styles.addressSelect} ${errors_inn && !result_valid_inn && styles.errorLineInn}`}
              value={innValue}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              open={isOpenInn}
              placeholder="Введите ИНН организации"
              onSearch={(e) => getInnValue({ value: e, dispatch, setInnValue })}
              onKeyDown={(e) => handleKeyDownInn({ e, onSubmitInnSelect })}
              onBlur={(e) => clickOutsideInnClose({ e, setIsOpenInn })}
              onChange={onChangeInnSelect}
              onClear={clearWorkForm}
              notFoundContent="Необходимо ввести ИНН организации"
            >
              {inn.length &&
                inn.map((item, index) => (
                  <Option key={item + index} value={item.value}>
                    {item.value}
                  </Option>
                ))}
            </Select>
            <Button
              name="searchButton"
              className={styles.btnSearchAddress}
              onClick={onSubmitInnSelect}
              type="default"
            >
              Найти
            </Button>
          </Form.Item>
          {errors_inn && !result_valid_inn
            ? (<span className={styles.errorsInn}>{errors_inn}</span>)
            : (<span className={styles.notError} />)
          }
        </Col>
        <Col sm={24} md={12}>
          <Form.Item label="ОГРН/ОГРНИП" name="ogrn">
            <InputStyle type="text" placeholder="Введите данные" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={32}>
        <Col sm={24} md={12}>
          <Form.Item label="Должность" name="position">
            <InputStyle type="text" placeholder="Введите данные" />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            label="Организация"
            name="place"
            rules={[
              { type: 'string', required: false, message: 'Место работы' },
            ]}
          >
            <InputStyle placeholder="Введите данные" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
