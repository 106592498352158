import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import Icon from '@ant-design/icons';
import { Col, Form, Radio, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from '../../../assets/icons/CalendarIcon';
import { CheckCircleIcon } from '../../../assets/icons/CheckCircleIcon';
import { CloseCircleIcon } from '../../../assets/icons/CloseCircleIcon';
import CheckboxStyle from '../../../style/CheckBox/CheckboxStyle';
import { InputStyle } from '../../../style/Input/InputStyle';
import { IMainInfo } from '../../../types/interfaces/interfaces';
import { calendarMask, fioValid, noLeadingTrailingSpaces } from '../../../utils';
import { onKeyDownDatePicker } from '../../../utils/ProfileRegistration';
import styles from './MainInfo.module.css';


export const MainInfo: FC<IMainInfo> = ({
  setCheckNick,
  checkNick,
  checkChange,
  form,
  startDate,
  getError,
  getDateValue,
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.mainInfo}>
      <Row gutter={[24, 24]}>
        <Col sm={24} md={12}>
          <Form.Item
            hasFeedback
            rules={[
              { message: 'Вы можете использовать кириллицу', pattern: fioValid },
              { message: 'Уберите лишние пробелы', pattern: noLeadingTrailingSpaces },
              { required: true, message: 'Введите фамилию' },
            ]}
            label="Фамилия"
            name="sur_name"
          >
            <InputStyle
              maxLength={40}
              name="sur_name"
              type="text"
              placeholder="Введите фамилию"
            />
          </Form.Item>
        </Col>
        <Col sm={24} md={12}>
          <Form.Item
            label="Имя"
            hasFeedback
            rules={[
              { message: 'Вы можете использовать кириллицу', pattern: fioValid },
              { message: 'Уберите лишние пробелы', pattern: noLeadingTrailingSpaces },
              { required: true, message: 'Введите имя' },
            ]}
            name="name"
          >
            <InputStyle maxLength={40} name="name" type="text" placeholder="Введите имя" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col sm={24} md={12}>
          <Form.Item
            className={styles.patronymic}
            label="Отчество"
            rules={[
              { message: 'Вы можете использовать кириллицу', pattern: fioValid },
              { message: 'Уберите лишние пробелы', pattern: noLeadingTrailingSpaces },
              { required: !checkNick, message: 'Введите отчество' },
            ]}
            hasFeedback
            name="patronymic"
          >
            <InputStyle
              name="patronymic"
              maxLength={40}
              disabled={checkNick}
              type="text"
              placeholder="Введите отчество"
            />
          </Form.Item>
          <div className={styles.checkBox}>
            <CheckboxStyle
              checked={checkNick}
              onChange={(e: CheckboxChangeEvent) => checkChange({ e, setCheckNick, form })}
            >
              Нет отчества
            </CheckboxStyle>
          </div>
        </Col>

        <Col sm={24} md={12}>
          <Form.Item
            hasFeedback
            label="Дата рождения"
            className={styles.formInputBlock}
            name="birthday"
          >
            <div className={styles.datePickerInputBlock}>
              <DatePicker
                placeholderText="Введите дату рождения"
                locale={ru}
                dateFormat="dd.MM.yyyy"
                className={`${styles.datePickerInput} ${
                  !startDate && getError ? styles.errorLine : styles.defaultLine
                }`}
                selected={startDate}
                onChange={(date: Date) => getDateValue(date)}
                onKeyDown={onKeyDownDatePicker}
                onFocus={() => setFocus(true)}
                onCalendarClose={() => setTimeout(() => setFocus(false), 150)}
                isClearable={getError && focus}
                maxDate={new Date()}
                customInput={
                  <MaskedTextInput type="text" mask={calendarMask} />
                }
              />
              <div className={styles.calendarBlockIcon}>
                <CalendarIcon />
              </div>
              <span className={styles.blueStar}>*</span>
            </div>
            <span className={styles.errorText}>
              {!startDate && getError && 'Выберите дату рождения'}
            </span>
            {getError ? (
              startDate ? (
                <Icon
                  component={CheckCircleIcon}
                  className={styles.checkCircleIcon}
                />
              ) : (
                <Icon
                  component={CloseCircleIcon}
                  className={styles.closeCircleIcon}
                />
              )
            ) : null}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form.Item
            label="Пол"
            name="sex"
            rules={[
              { required: true, message: 'Выберите пол' },
            ]}
            className={styles.genderChoose}
          >
            <div onKeyDown={handleKeyDown}>
              <Radio.Group name="sex">
                <Radio value="male"> Мужской </Radio>
                <Radio value="female"> Женский </Radio>
              </Radio.Group>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
