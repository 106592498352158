import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorIcon } from '../../assets/icons/ErrorIcon';
import { SuccessIcon } from '../../assets/icons/SuccessIcon';
import { ProfileService } from '../../services/profileService';
import { IBindLeaderId, ICallbackLeaderId } from '../../types/interfaces/interfaces';
import { changeLeaderIdStatus } from '../profileSlice';

export const bindLeaderId = createAsyncThunk(
  'profileSlice/bindLeaderId',
  async(data: IBindLeaderId) => {
    const value = JSON.stringify(data);
    const response = await ProfileService.$bindLeaderId(value);
    if (response?.data.status) {
      window.location.href = response.data.values[0];
    }
    else {
      const err = response?.data.errors[0].message;
      message.error({
        className: 'messageError',
        content: err,
        icon: <ErrorIcon />,
      });
    }
  },
);

export const callbackLeaderId = createAsyncThunk(
  'profileSlice/callbackLeaderId',
  async(data: ICallbackLeaderId, { dispatch }) => {
    const value = JSON.stringify(data);
    const response = await ProfileService.$callbackLeaderId(value);
    if (response?.data.status) {
      dispatch(changeLeaderIdStatus(true));
      message.success({
        className: 'messageSuccess',
        content: 'Успешно привязан Leader Id',
        icon: <SuccessIcon />,
      });
    }
    else {
      message.error({
        className: 'messageError',
        content: response?.data.errors[0].message,
        icon: <ErrorIcon />,
      });
    }
  },
);

export const disconnectLeaderId = createAsyncThunk(
  'profileSlice/disconnectWithLeaderId',
  async(token: string, { dispatch }) => {
    const value = JSON.stringify({ token });
    const response = await ProfileService.$disconnectWithLeaderId(value);
    if (response?.data.status) {
      dispatch(changeLeaderIdStatus(false));
      message.success({
        className: 'messageSuccess',
        content: 'Вы отвязали свой аккаунт от Leader Id',
        icon: <SuccessIcon />,
      });
    }
    else {
      message.error({
        className: 'messageError',
        content: response?.data.errors[0].message,
        icon: <ErrorIcon />,
      });
    }
  },
);
