export const LeaderIdIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="currentColor" />
    <g clipPath="url(#clip0_213_5103)">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.44824 18.376H7.77291V6H5.44824V18.376ZM16.2122 6H9.32291V18.376H16.2122L19.3976 15.3775V8.9991L16.2122 6ZM11.6476 16.556H17.4602V8.184H11.6476V16.556Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.0732 17.648L19.3979 15.5245V8.85158L17.0732 6.72803V17.648Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_213_5103">
        <rect width="15" height="13" fill="currentColor" transform="translate(5 6)" />
      </clipPath>
    </defs>
  </svg>
);
