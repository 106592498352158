import React, { FC, useState } from 'react';
import { PasswordEdit } from './PasswordEdit/PasswordEdit';
import styles from './Profile.module.css';
import { ProfileEdit } from './ProfileEdit/ProfilEdit';
import ProfileView from './ProfileView/ProfileView';

export const ProfileInd: FC = () => {
  const [isProfileView, setIsProfileView] = useState<string>('profileView');

  const profileRoutes = () => {
    if (isProfileView === 'profileView') {
      return <ProfileView setIsProfileView={setIsProfileView} />;
    }
    if (isProfileView === 'profileEdit') {
      return <ProfileEdit setIsProfileView={setIsProfileView} />;
    }
    if (isProfileView === 'passwordEdit') {
      return <PasswordEdit setIsProfileView={setIsProfileView} />;
    }
    return <p>View not found</p>;
  };

  return <div className={styles.profile__wrapper}>{profileRoutes()}</div>;
};
