import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ErrorIcon } from '../../assets/icons/ErrorIcon';
import { ProfileService } from '../../services/profileService';
import { IDeleteDocs, IFileEdit, IFileUpload } from '../../types/interfaces/interfaces';
import { errorsKey } from '../../utils';
import { objConversion } from '../../utils/docUtils';
import { Diploma, Is_Load_But, Passport, ProctoringDelete, Qualification } from '../profileSlice';
import { get_users } from './profileAction';

// загрузка документов
export const upload_doc = createAsyncThunk(
  'profileSlice/upload_doc',
  async(
    {
      token,
      file,
      type,
      name,
      description,
      diploma,
      passport,
      qualification,
      proctoring,
    }: IFileUpload,
    { dispatch },
  ) => {
    dispatch(Is_Load_But(true));
    const data = new FormData();
    data.append('token', token);
    data.append('type', type);
    data.append('name', name);
    data.append('description', description);
    data.append('file', file);
    const response = await ProfileService.$upload_doc(data);
    if (response?.data.status) {
      dispatch(Is_Load_But(false));
      const imageData = {
        name,
        description,
        type,
        image: response.data.values[0],
      };
      if (type === 'diploma') {
        dispatch(Diploma([...diploma, imageData]));
      }
      else if (type === 'passport') {
        dispatch(Passport([...passport, imageData]));
      }
      else if (type === 'qualification') {
        dispatch(Qualification([...qualification, imageData]));
      }
      else if (type === 'proctoring') {
        dispatch(get_users(token));
      }
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
    dispatch(Is_Load_But(false));
  },
);

// удаление документа
export const deleteDoc = createAsyncThunk(
  'profileSlice/deleteDoc',
  async(
    {
      token,
      type,
      name,
      diploma,
      passport,
      qualification,
      proctoring,
    }: IDeleteDocs,
    { dispatch },
  ) => {
    const data = new FormData();
    data.append('token', token);
    data.append('type', type);
    data.append('file_name', name);
    const response = await ProfileService.$delete_doc(data);
    if (response?.data.status) {
      if (type === 'diploma') {
        dispatch(Diploma(diploma.filter((item) => item.image !== name)));
      }
      else if (type === 'passport') {
        dispatch(Passport(passport.filter((item) => item.image !== name)));
      }
      else if (type === 'qualification') {
        dispatch(Qualification(qualification.filter((item) => item.image !== name)));
      }
      else if (type === 'proctoring') {
        dispatch(ProctoringDelete([...proctoring.filter((item) => item.image !== name), { type: null }]));
      }
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
  },
);

// изменение документа
export const editDoc = createAsyncThunk(
  'profileSlice/editDoc',
  async(
    {
      token,
      file,
      type,
      name,
      description,
      diploma,
      passport,
      qualification,
      oldFile,
    }: IFileEdit,
    { dispatch },
  ) => {
    dispatch(Is_Load_But(true));
    const data = new FormData();
    data.append('token', token);
    data.append('type', type);
    data.append('name', name);
    data.append('description', description);
    data.append('file', file);
    data.append('old_file', oldFile);
    const response = await ProfileService.$edit_doc(data);
    if (response?.data.status) {
      const imageData = {
        oldName: oldFile,
        name,
        description,
        type,
        image: response?.data.values[0],
      };
      if (type === 'diploma') {
        const data = objConversion(diploma, imageData);
        dispatch(Diploma(data));
      }
      else if (type === 'passport') {
        const data = objConversion(passport, imageData);
        dispatch(Passport(data));
      }
      else if (type === 'qualification') {
        const data = objConversion(qualification, imageData);
        dispatch(Qualification(data));
      }
    }
    else {
      const err = response?.data.errors[0].key;
      const show = errorsKey(err);
      message.error({
        className: 'messageError',
        content: show,
        icon: <ErrorIcon />,
      });
    }
    dispatch(Is_Load_But(false));
  },
);
