export const emailValid = new RegExp(/^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9-]+\.[a-zA-Z0-9-а-яА-Я.]+)$/);
export const phoneValid = new RegExp(/^(\+)?(\(\d{2,3}\) ?|\d)(([ \-]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/);
export const passValid = new RegExp(/^[a-zA-Z0-9^\u0021-\u007E]+$/);
export const calendarMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
export const fioValid = new RegExp(/^(?=.{1,40}$)\s*[а-яёА-ЯЁ]+(?:[-' ][а-яёА-ЯЁ]+)*\s*$/);
export const noLeadingTrailingSpaces = new RegExp(/^(?! ).*(?<! )$/);
export const mobileValide = new RegExp(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);

const errosServ = [
  { key: 99, message: 'Неизвестная ошибка' },
  { key: 100, message: 'Пустой запрос' },
  { key: 101, message: 'Запрос на чтение ошибки' },
  { key: 102, message: 'Ошибка запроса демаршалировать' },
  { key: 103, message: 'Неверные данные' },
  { key: 104, message: 'Неправильный метод' },
  { key: 105, message: 'Токен не найден' },
  { key: 106, message: 'Пользователь не найден' },
  { key: 107, message: 'Путь не разрешен' },
  { key: 108, message: 'Ошибка отправки данных регистрации пользователя' },
  { key: 109, message: 'Введен некорректный номер телефона' },
  { key: 110, message: 'Неправильно ввели адрес электронной почты пользователя' },
  { key: 111, message: 'Неверный пароль пользователя' },
  { key: 112, message: 'Телефон уже существует' },
  { key: 113, message: 'Email уже существует' },
  { key: 114, message: 'Ошибка добавить пользователя' },
  { key: 115, message: 'Ошибка отправки данных авторизации пользователя' },
  { key: 116, message: 'Электронная почта и телефон не переданы' },
  { key: 117, message: 'Пароль не прошел' },
  { key: 118, message: 'Электронная почта или телефон или пароль не верны' },
  { key: 119, message: 'Ошибка отправки смс' },
  { key: 120, message: 'Код не прошел' },
  { key: 121, message: 'Код неверный' },
  { key: 122, message: 'Ошибка проверки кода пользователя' },
  { key: 123, message: 'Токен не активный' },
  { key: 124, message: 'Ошибка обновления пользователя' },
  { key: 131, message: 'Пароль должен содержать более 5 и менее 20 символов' },
  { key: 150, message: 'Ошибка загрузки файла' },
  { key: 157, message: 'Телефон пользователя уже установлен' },
  { key: 158, message: 'Такой номер существует' },
  { key: 161, message: 'Неверный код' },
  { key: 164, message: 'Ошибка данных снилс' },
  { key: 165, message: 'Ошибка отправки электронного письма' },
  { key: 166, message: 'Адрес электронной почты пользователя уже установлен' },
  { key: 167, message: 'Адрес электронной почты некорректен' },
  { key: 170, message: 'Такой СНИЛС уже зарегистрирован' },
  { key: 171, message: 'Вы не можете запросить код более одного раза в две минуты' },
  { key: 172, message: 'Пользователь удален или заблокирован' },
  { key: 176, message: 'Ошибка проверки пользователя' },
  { key: 183, message: 'Документ удостоверяющий личность уже существует' },
  { key: 187, message: 'Ошибка обновления изображения документа пользователя' },
  { key: 189, message: 'Документ не найден' },
  { key: 210, message: 'Невозможно удалить прокторинг по user_id и file_name' },
  { key: 211, message: 'Proctoring get single face' },
  { key: 236, message: 'Неправильный домен электронной почты' },
  { key: 224, message: 'Error send telegram message' },
  { key: 246, message: 'Отправка sms на иностранный номер невозможна' },
  { key: 247, message: 'Для иностранного номера нужно заполнить вкладку Не являюсь гражданином РФ' },
  { key: 253, message: 'Неправильное отчество' },
  { key: 254, message: 'Техническая ошибка' },
  { key: 255, message: 'Техническая ошибка' },
  { key: 256, message: 'Техническая ошибка' },
  { key: 257, message: 'Найдено много лиц' },
  { key: 258, message: 'Лицо не найдено' },
  { key: 259, message: 'Другой человек' },
  { key: 266, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.' },
  { key: 267, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.' },
  { key: 268, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.' },
  { key: 269, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.' },
  { key: 270, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.' },
  { key: 271, message: 'Невозможно осуществить действие: учетная запись на Госуслугах не подтверждена или находится на проверке.' },
  { key: 272, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.' },
  { key: 273, message: 'Отсутствует привязка к сервису Госуслуг. Осуществите привязку в своем профиле ЕМОП.' },
  { key: 7777, message: 'Вы не заполнили обязательные поля' },
  { key: 7778, message: 'Вы не подтвердили почту' },
  { key: 7779, message: 'Вы не подтвердили телефон' },
  { key: 8888, message: 'Слишком большой файл' },
  { key: 9999, message: 'Пустые поля' },
];

export const errorsKey = (key:number | undefined) => errosServ.filter((er) => er.key === key).map((item) => item.message);
