import React, { FC, ReactElement, useEffect, useState } from 'react';
import { PinInput } from 'react-input-pin-code';
import { Button, Modal } from 'antd';
import Text from 'antd/es/typography/Text';
import { confirmPhoneProfile } from '../../../store/actions/profileAction';
import { ModalVisibleForPhone } from '../../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { IConfirmPhone } from '../../../types/interfaces/interfaces';
import { brightRed, greenRgbColor, pastelBlue, PinContainerStyle, PinInputStyle } from '../../../utils/const';
import { regexObj } from '../../../utils/PhoneFilter';
import styles from '../CodeRequestPhone/CodeRequestPhone.module.css';
import { Counter } from '../CodeRequestPhone/Counter';

export const ConfirmPhoneProfile: FC<IConfirmPhone> = ({ visible, token }) => {
  const [timer, setTimer] = useState(120);
  const dispatch = useAppDispatch();
  const { _isLoadButton, modifiedPhone, acronym } = useAppSelector(({ profileSlice }) => profileSlice);
  const [values, setValues] = useState(new Array(4).fill(''));
  const [maskPhone, setMaskPhone] = useState<string>('');

  const phone: ReactElement = <span className={styles._phone}> {maskPhone} </span>;

  const confirmCode = () => {
    dispatch(confirmPhoneProfile({ token, code: values.join(''), phone: modifiedPhone }));
  };

  const handleChangeOnCancel = () => {
    dispatch(ModalVisibleForPhone(false));
    setTimer(120);
  };

  useEffect(() => {
    setMaskPhone(modifiedPhone.replace(regexObj[acronym].regex, regexObj[acronym].regexTransform));
  }, [modifiedPhone]);

  return (
    <div>
      <Modal
        centered={true}
        title={null}
        footer={null}
        open={visible}
        className={styles._confirmModal}
        onCancel={handleChangeOnCancel}
      >
        <div className={styles._body}>
          <h2 className={styles._modalTitle}>Подтвердить номер телефона</h2>
          <Text className={styles._modalText}>Мы отправили код на номер {phone}</Text>

          <PinInput
            containerStyle={PinContainerStyle}
            inputStyle={PinInputStyle}
            autoFocus={true}
            placeholder={''}
            values={values}
            onChange={(value, index, onChangeValues) => setValues(onChangeValues)}
            validBorderColor={greenRgbColor}
            focusBorderColor={pastelBlue}
            errorBorderColor={brightRed}
          />
          <Counter
            counter={timer}
            setCounter={setTimer}
            phone={modifiedPhone}
          />
          <div className={styles._modalBtnBlock}>
            <Button
              disabled={values.join('').length < 4}
              onClick={confirmCode}
              type="primary"
              size={'large'}
              className={styles.styleButton}
              loading={_isLoadButton}
            >
              Отправить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
