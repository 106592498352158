import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { get_users } from '../../store/actions/profileAction';
import { s3Token, Token } from '../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import IsLoadingComponent from '../../style/Loading/IsLoadingComponent';


export const Main: FC = () => {
  const { _IsLoading } = useAppSelector(({ profileSlice }) => profileSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.unione.getUnioneToken()
      .then((token) => {
        if (token === null || token === undefined || token === 'null') {
          window.unione.goToAuth();
        }
        else {
          dispatch(Token(String(token)));
          dispatch(get_users(String(token)));
        }
      }).catch(() => {
        window.unione.goToAuth();
      });
  }, []);

  useEffect(() => {
    window.unione.getS3Token()
      .then((token) => {
        if (token === null || token === undefined || token === 'null') {
          window.unione.goToAuth();
        }
        else {
          dispatch(s3Token(String(token)));
        }
      }).catch(() => {
        window.unione.goToAuth();
      });
  }, []);


  return (
    <Layout className="backgroundLayout">
      <Content className="mainBlock">
        {_IsLoading ? <IsLoadingComponent /> : <Outlet />}
      </Content>
    </Layout>
  );
};
