import React, { FC } from 'react';
import { PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Text from 'antd/es/typography/Text';
import cn from 'classnames';
import pdfIcon from '../../../assets/image/pdf2.png';
import { IDocumentationEditModal } from '../../../types/interfaces/interfaces';
import { save } from '../../../utils/const';
import { documentUrl } from '../../../utils/ProfileRegistration';
import styles from './DocumentationEditModal.module.css';

export const DocumentationEditModal: FC<IDocumentationEditModal> = ({
  editModal,
  modal,
  onClose,
  saveDocFile,
  _isLoadButton,
  handleUpload,
  isPdf,
  showImage,
  errorFile,
  errorName,
  onChangeFileName,
  fileName,
  errorDescription,
  onChangeFileDescription,
  fileDescription,
  oldFile,
  s3_token,
}) => {
  const renderModalTitle = () => (
    <Row>
      <div>
        <h2 className={styles.edit_title}>
          {editModal ? 'Редактирование файла' : 'Добавление файла'}
        </h2>
      </div>
      <p className={styles.edit_description}>
        Вы можете добавить скан паспорта, обновить имя файла и добавить к
        нему описание
      </p>
    </Row>
  );

  const renderModalFooter = () => (
    <>
      <div className={styles.lineFooter} />
      <Row className={styles.edit_row} justify={'space-between'}>
        <Col span={24} className={styles.footer}>
          <div className={styles.areaDragBtn}>
            <label className={styles.areaDragBtnLabel}>
              <Text className={cn(styles.labelButton, { [styles.isLoading]: _isLoadButton })}>
                Выбрать другой файл
              </Text>
              <input
                className={styles.inputStyleBtn}
                type="file"
                accept=".png,.jpeg,.jpg,.pdf,.gif"
                onChange={handleUpload}
              />
            </label>
          </div>
          <div>
            <Button
              type="link"
              className={styles._buttonCancel}
              onClick={onClose}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              onClick={saveDocFile}
              loading={_isLoadButton}
            >
              {save}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      <Modal
        className={styles.modal_header}
        title={renderModalTitle()}
        open={modal}
        onCancel={onClose}
        width={700}
        centered={true}
        bodyStyle={{ height: 400 }}
        footer={renderModalFooter()}
      >
        <Row justify={'center'} align={'middle'}>
          <Col className={cn(styles._modalWindow, { [styles.errorFileModal]: errorFile })}>
            {isPdf === 'pdf' ? (
              <img
                src={pdfIcon}
                alt="avatar"
              />
            ) : showImage ? (
              <Col>
                <img
                  src={showImage}
                  className={styles.avatarBorder}
                  alt="avatar"
                  onError={({ currentTarget }) => documentUrl(currentTarget, oldFile, s3_token)}
                />
                <input
                  className={styles.inputStyle}
                  type="file"
                  accept=".png,.jpeg,.jpg,.pdf,.gif"
                  onChange={handleUpload}
                />
              </Col>
            ) : (
              <Col className={styles.modalCol}>
                <div className={styles.areaDrag}>
                  <label className={styles.label}>
                    <input
                      className={styles.inputStyle}
                      type="file"
                      accept=".png,.jpeg,.jpg,.pdf,.gif"
                      onChange={handleUpload}
                    />
                    <Col>
                      <div className={styles.justify_center}>
                        <PaperClipOutlined className={styles.clip_icon} />
                        <p
                          className={styles.blueText_WithIcon}
                          style={{ margin: '0' }}
                        >
                          Переместите файлы сюда или выберите файлы
                        </p>
                        <p className={styles.document_format}>Допустимые форматы: .pdf .jpg .png .gif Максимальный размер файла: 5 Мб</p>
                      </div>
                    </Col>
                  </label>
                </div>
              </Col>
            )}
            {errorFile ? <Text type="danger" className={styles.errorFile}>Загрузите файл</Text> : <div className={styles.errorContainer} />}
          </Col>

          <Col className={cn(styles.width_100, styles.marginTop20)}>
            <Input
              className={cn(styles.inputFile_modal, { [styles.errorLine]: errorName })}
              placeholder={'Введите название файла'}
              onChange={onChangeFileName}
              value={fileName}
              status={errorName ? 'error' : undefined}
            />
            {errorName ? <Text type="danger">Введите корректное название файла</Text> : <div className={styles.errorContainer} />}
          </Col>

          <Col className={styles.width_100}>
            <TextArea
              className={cn(styles.inputFile_modal2, { [styles.errorLine]: errorDescription })}
              placeholder={'Введите описание файла'}
              onChange={onChangeFileDescription}
              value={fileDescription}
              status={errorDescription ? 'error' : undefined}
              autoSize={{ minRows: 2, maxRows: 2 }}
            />
            {errorDescription && <Text type="danger">Введите корректное описание файла</Text>}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
