import React, { FC, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { LeaderIdIcon } from '../../../assets/icons/LeaderIdIcon';
import { PasswordIcon } from '../../../assets/icons/PasswordIcon';
import { PublicServicesIcon } from '../../../assets/icons/PublicServicesIcon';
import { TelegramIcon } from '../../../assets/icons/TelegramIcon';
import { getEsiaLoginLink } from '../../../store/actions/esiaActions';
import { bindLeaderId } from '../../../store/actions/leaderIdActions';
import { BindTelegramModalVisible } from '../../../store/profileSlice';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { ILoginToOneId } from '../../../types/interfaces/interfaces';
import { unbind } from '../../../utils/const';
import { phoneMask } from '../../../utils/PhoneFilter';
import { BindTelegramModal } from '../../Modal/BindTelegramModal/BindTelegramModal';
import { ConfirmTelegramModal } from '../../Modal/ConfirmTelegramModal/ConfirmTelegramModal';
import { ConfirmUnbindEsia } from '../../Modal/ConfirmUnbindEsia/ConfirmUnbindEsia';
import { ConfirmUnbindLeaderId } from '../../Modal/ConfirmUnbindLeaderId/ConfirmUnbindLeaderId';
import styles from './LoginToOneID.module.css';


export const LoginToOneId: FC<ILoginToOneId> = ({ setIsProfileView }) => {
  const { values } = useAppSelector(({ profileSlice }) => profileSlice);
  const dispatch = useAppDispatch();
  const [confirmUnbind, setConfirmUnbind] = useState<boolean>(false);
  const [confirmUnbindEsia, setConfirmUnbindEsia] = useState<boolean>(false);
  const [isLoadingEsia, setIsLoadingEsia] = useState<boolean>(false);
  const [isLoadingLeaderId, setIsLoadingLeaderId] = useState<boolean>(false);

  const hasPassowrd = values.has_pass;
  const leaderIdStatus = values.is_leader_id;
  const esiaStatus = values.is_esia;
  const telegramIdStatus = values.confirm.telegram;
  const userPhone = phoneMask(values.phone);
  const userEmail = values.email;

  const onBind = () => {
    if (!isLoadingLeaderId) {
      setIsLoadingLeaderId(true);
      const data = {
        phone: userPhone,
        email: userEmail,
      };
      dispatch(bindLeaderId(data)).finally(() => {
        setIsLoadingLeaderId(false);
      });
    }
  };

  const onBindPublicServices = () => {
    if (!isLoadingEsia) {
      setIsLoadingEsia(true);
      dispatch(getEsiaLoginLink()).finally(() => {
        setIsLoadingEsia(false);
      });
    }
  };

  const onBindTelegram = () => {
    dispatch(BindTelegramModalVisible(true));
  };

  return (
    <div className={styles.main}>
      <Row className={styles.userInfo}>
        <Col span={24}>
          <h3 className={styles.userInfoTitle}>Вход в OneID</h3>
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]} wrap={true} justify="end">
            <Col xs={24} sm={24} lg={12}>
              <div className={styles.userInfoBlock}>
                <Icon component={PasswordIcon} />
                <div className={styles.userInfoBlockMain}>
                  <p className={styles.userInfoValue}>Пароль</p>
                  {hasPassowrd
                    ? <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>Пароль задан</p>
                    : <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>Пароль не задан</p>
                  }
                </div>
                {hasPassowrd
                  ? (
                    <Button
                      type="link"
                      className={styles.userInfoPasswordBtn}
                      onClick={() => setIsProfileView('passwordEdit')}
                    >
                      Изменить пароль
                    </Button>
                  )
                  : (
                    <Button
                      type="link"
                      className={styles.userInfoPasswordBtn}
                      onClick={() => setIsProfileView('passwordEdit')}
                    >
                      Задать пароль
                    </Button>
                  )
                }
              </div>
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <div className={styles.userInfoBlock}>
                <Icon
                  component={LeaderIdIcon}
                  className={leaderIdStatus ? styles.leaderIdIconBind : styles.iconUnbind}
                />
                <div className={styles.userInfoBlockMain}>
                  <p className={styles.userInfoValue}>LeaderID</p>
                  {leaderIdStatus
                    ? <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>Подключено</p>
                    : <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>Не подключено</p>
                  }
                </div>
                {leaderIdStatus
                  ? (
                    <Button
                      type="link"
                      className={styles.userInfoPasswordBtn}
                      onClick={() => setConfirmUnbind(true)}
                    >
                      {unbind}
                    </Button>
                  )
                  : (
                    <Button
                      type="link"
                      className={styles.userInfoPasswordBtn}
                      onClick={onBind}
                    >
                      Привязать
                    </Button>
                  )
                }
              </div>
            </Col>

            <Col xs={24} sm={24} lg={12}>
              <div className={styles.userInfoBlock}>
                <Icon
                  component={PublicServicesIcon}
                  className={esiaStatus ? styles.leaderIdIconBind : styles.iconUnbind}
                />
                <div className={styles.userInfoBlockMain}>
                  <p className={styles.userInfoValue}>Госуслуги</p>
                  <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>
                    {esiaStatus ? 'Подключено' : 'Не подключено'}
                  </p>
                </div>
                {esiaStatus
                  ? (
                    <Button
                      type="link"
                      className={styles.userInfoPasswordBtn}
                      onClick={() => setConfirmUnbindEsia(true)}
                    >
                      {unbind}
                    </Button>
                  )
                  : (
                    <Button
                      type="link"
                      className={styles.userInfoPasswordBtn}
                      onClick={onBindPublicServices}
                    >
                    Привязать
                    </Button>
                  )
                }
              </div>
            </Col>

            <Col xs={24} sm={24} lg={12}>
              <div className={styles.userInfoBlock}>
                <Icon
                  component={TelegramIcon}
                  className={telegramIdStatus ? styles.telegramIconBind : styles.iconUnbind}
                />
                <div className={styles.userInfoBlockMain}>
                  <p className={styles.userInfoValue}>Telegram</p>
                  {telegramIdStatus
                    ? <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>Подключено</p>
                    : <p className={`${styles.userInfoLabel} ${styles.userInfoLabelUnder}`}>Не подключено</p>
                  }
                </div>
                {!telegramIdStatus && (
                  <Button
                    type="link"
                    className={styles.userInfoPasswordBtn}
                    onClick={onBindTelegram}
                  >
                    Привязать
                  </Button>
                )
                }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <ConfirmUnbindLeaderId
        visible={confirmUnbind}
        setConfirmUnbind={setConfirmUnbind}
      />

      <ConfirmUnbindEsia
        visible={confirmUnbindEsia}
        setConfirmUnbind={setConfirmUnbindEsia}
      />

      <BindTelegramModal />

      <ConfirmTelegramModal />
    </div>
  );
};
