import { IDocImage } from '../types/entities/entitites';

export const objConversion = (arr: IDocImage[], obj: IDocImage) => arr.map((item) => {
  if (item.image === obj.oldName) {
    const imageObj = obj.image ? obj.image : obj.oldName;
    return {
      ...item,
      name: obj.name,
      description: obj.description,
      type: obj.type,
      image: imageObj,
    };
  }
  return item;
});
