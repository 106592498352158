import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Wrapper } from '@unione-pro/unione.commons.wrapper';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { store } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Wrapper>
        <App />
      </Wrapper>
    </Provider>
  </BrowserRouter>,
);
