import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import cn from 'classnames';
import { AvatarIcon } from '../../assets/icons/AvatarIcon';
import { IAvatarProps } from '../../types/interfaces/interfaces';
import styles from './Avatar.module.css';

export const Avatar: FC<IAvatarProps> = ({ avatarUrl, isVerify, onError }) => {
  const avatarImgClass = cn(styles.avatarImage, {
    [styles.isVerify]: isVerify,
  });
  const avatarIconClass = cn(styles.avatarIcon, {
    [styles.isVerify]: isVerify,
  });

  if (avatarUrl) {
    return (
      <img
        src={avatarUrl}
        className={avatarImgClass}
        alt="user_avatar"
        onError={onError}
      />
    );
  }

  return <Icon component={AvatarIcon} className={avatarIconClass} />;
};
